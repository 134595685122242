import { capitalize, toString as ldToString } from 'lodash';

const eventNames = {
  ADD_RATERS: 'Add Raters',
  ADD_SESSION_TO_CALENDAR: 'Add Session to Calendar',
  ACCEPT_INVITE: 'Accept Invite',
  ACCEPT_RATER_INVITATION: 'Accept Rater Invitation',
  ACCEPT_TEAM_INVITE: 'Accept Team Invite',
  ACCEPT_METHOD: 'Accept Method',
  ACTIVITY_LINK_CLICKED: 'Activity Link Clicked',
  BEGIN_NEW_COMPARISON: 'Begin New Comparison',
  BLOCK_BUTTON_CLICKED: 'Block Button Clicked',
  CHANGE_USER_AVATAR: 'Change User Avatar',
  CLICK_CTA: 'Click CTA',
  CLICK_DASHBOARD_TIPS: 'Click Dashboard Tips',
  COMPLETE_ONBOARDING: 'Complete Onboarding',
  COMPLETE_ONBOARDING_STEP: 'Complete Onboarding Step',
  COMPLETE_PROFILE: 'Complete Profile',
  COMPLETE_REGISTRATION: 'Complete Registration',
  CREATE_GROUP: 'Create Assessment Group',
  CREATE_TEAM: 'Create Team',
  CULTURE_CLICKED: 'Culture Clicked',
  DASHBOARD_SEARCH: 'Dashboard Search',
  DOWNLOAD_INDIVIDUAL_REPORT: 'Download Individual Report',
  DOWNLOAD_PROFILE: 'Download GlobeSmart Profile',
  DOWNLOAD_COMPARISON_CHART_SCREENSHOT: 'Download Comparison Chart Screenshot',
  EMAIL_GROUP_REPORT: 'Email Group Report',
  EMAIL: 'Email',
  EMAIL_INDIVIDUAL_REPORT: 'Email Individual Report',
  EMAIL_ORGANIZATION_REPORT: 'Email Organization Report',
  EMAIL_VERIFICATION_SENT: 'Email Verification Sent',
  HEADER_IMAGE_CLICKED: 'Header Image Clicked',
  INVITE_TO_ORGANIZATION: 'Invite to Organization',
  INVITE_TRIAL_PARTICIPANTS: 'Invite Trial Participants',
  JOIN_ASSESSMENT_GROUP: 'Join Assessment Group',
  LM_SEARCH: 'Learning Module Search',
  LOGIN: 'Login',
  LOGIN_REDIRECT_FROM_ONBOARDING: 'Login Redirect From Onboarding',
  NAME: 'Name',
  OPEN_PURCHASE_WINDOW: 'Open Purchase Window',
  PERSON_TYPE_SELECTION: 'Person Type Selection',
  PRODUCT_TRACK: 'Product Track',
  REDEEM_ACCESS_CODE: 'Redeem Access Code',
  REMOVE_CONNECTION: 'Remove Connection',
  REORDER_DASHBOARD_BLOCKS: 'Reorder Dashboard Products',
  SEND_INVITE: 'Send Invite',
  SHARE_COURSE_CATALOG: 'Share Course Catalog',
  SHARE_PROFILE_CLICKED: 'Share Profile Clicked',
  SHARE_SESSION: 'Share Session',
  SHARE_SKILL_CLICKED: 'Share Skill Clicked',
  SIGN_UP: 'Sign Up',
  START_ONBOARDING: 'Start Onboarding',
  SUBMIT_PURCHASE_FORM: 'Submit Purchase Form',
  SURVEY_QUESTION_SUBMITTED: 'Survey Question Submitted',
  VIEW_ADVICE: 'View Advice',
  VIEW_APERIAN_LIVE_HOME: 'View Aperian Live Home',
  VIEW_ASSESSMENT_HOME: 'View Assessment Home',
  VIEW_BANNER_RESOURCE: 'View Banner Resource',
  VIEW_COURSE_CATALOG_PAGE: 'View Course Catalog Page',
  VIEW_DASHBOARD: 'View Dashboard',
  VIEW_FULLSCREEN: 'View Fullscreen',
  VIEW_GUIDES_COPILOT: 'View Guides Copilot',
  VIEW_GUIDES_HOME: 'View Guides Home',
  VIEW_IBI_LEARNING_MODULE: 'View IBI Dimensions Learning Module',
  VIEW_IBI_RESULTS: 'View IBI Results',
  VIEW_INSIGHTS_AND_TRENDS: 'View Insights and Trends',
  VIEW_LEARNING_MODULE_HOME: 'View Learning Module Home',
  VIEW_MANAGE_CONNECTIONS: 'View Manage Connections',
  VIEW_SESSION_DETAILS: 'View Session Details',
  VIEW_MY_TEAM: 'View My Teams',
  VIEW_GS_LEARNING_MODULE: 'View GS Dimensions Learning Module',
  VIEW_TEAM_PROFILE_ACTIVITY: 'View Team Profile Activity',
  VIEW_TRIAL_SIGNUP_FORM: 'View Trial Signup Form',
  WATCH_VIDEO: 'Watch Video',
};

const eventProps = {
  ACCEPT_METHOD: 'Accept Method',
  ACCESS_CODE_ID: 'Access Code Id',
  ACTIVE_BLOCKS: 'Active Blocks',
  ACTIVITY_NAME: 'Name of Activity',
  ADD_TO_CALENDAR_METHOD: 'Add to Calendar Method',
  INACTIVE_BLOCKS: 'Inactive Blocks',
  ADVICE_TOPIC: 'Advice Topic',
  AMOUNT: 'Amount',
  ASSESSMENT: 'Assessment',
  AUTH_TYPE: 'Auth Type',
  AVATAR_TYPE: 'Avatar Type',
  BLOCK_INTERNAL_NAME: 'Internal Name',
  BLOCK_TITLE: 'Title',
  BLOCK_LAUNCHED_FROM_PAGE: 'Launched From Page',
  BUILD_METHOD: 'Build Method',
  BUTTON_TEXT: 'Button Text',
  COMPARISON_OBJECT: 'Comparison Object',
  CUSTOM_MESSAGE: 'Custom Message',
  COHORT_CODE: 'Cohort Code',
  COUPON_CODE: 'Coupon Code',
  CULTURE_NAME: 'Culture Name',
  DETAILS_METHOD: 'Details Method',
  DOWNLOAD_LOCATION: 'Download Location',
  EMAIL_OF_USER_REMOVED: 'Email of User Removed',
  EMAIL: 'Email',
  FACILITATOR: 'Facilitator',
  FAILURE_REASON: 'Failure Reason',
  FROM: 'From',
  GROUP_ASSESSMENT: 'Assessment',
  GROUP_MEMBERS: 'Group Members',
  GROUP_NAME: 'Group Name',
  GROUP_SIZE: 'Group Size',
  INVITEES: 'Invitees',
  INVITATION_METHOD: 'Invitation Method',
  LANGUAGE: 'Language',
  LINK: 'Link',
  MODULE_NAME: 'Module Name',
  MULTIPLE_COMPARISON: 'Multiple Comparison',
  NAME: 'Name',
  NUMBER_INVITATIONS_ACCEPTED: 'Number of Invitations Accepted',
  NUMBER_INVITATIONS_SENT: 'Number of Invitations Sent',
  ONBOARDING_STEP: 'Onboarding Step',
  ORGANIZATION_NAME: 'Organization Name',
  OWN_REPORT: 'Own Report',
  PAGE_TITLE: 'Page Title',
  PAGE_URL: 'Page URL',
  PLAN_ID: 'Plan Id',
  PLAN_NICKNAME: 'Plan Nickname',
  PRODUCT_TRACK_SELECTED: 'Product Track Selected',
  PURCHASING_FOR_OTHERS: 'Purchasing For Others',
  QUANTITY: 'Quantity',
  RELATED_SKILL: 'Related Skill',
  REPORT_FOR_ORG: 'Report for Org',
  RESOURCE_NAME: 'Resource Name',
  SHARE_TYPE: 'Share Type',
  SUCCESS: 'Success',
  SURVEY_TAKER: 'Survey Taker',
  TIP_TEXT: 'Tip Text',
  TIP_TYPE: 'Tip Type',
  TEAM_MEMBERS: 'Team Members',
  TEAM_NAME: 'Team Name',
  TEAM_SIZE: 'Team Size',
  TEAMMATE: 'Teammate',
  TRIAL_INVITATION_PARTICIPANTS: 'Trial Invitation Participants',
  TRIAL_INVITEE_METHOD: 'Trial Invitee Method',
  SEARCH_TERM: 'Search Term',
  SELECTION: 'Selection',
  SESSION_DISPLAY_NAME: 'Session Display Name',
  SESSION_DURATION: 'Session Duration',
  SESSION_NAME_INTERNAL: 'Session Name Internal',
  SESSION_START_DATETIME: 'Session Start Datetime',
  SESSION_TYPE: 'Session Type',
  SHARED_FROM: 'Shared from',
  SHARE_METHOD: 'Share Method',
  SKILL_NAME: 'Skill Name',
  SORTED_BY: 'Sorted By',
  VIDEO_TITLE: 'Video Title',
  WISTIA_ID: 'Wistia ID',
  MEMBER_TO_RATE: 'Member to Rate',
  RATER_EMAILS: 'Rater emails',
  II_SCORE: 'II Score',
  ES_SCORE: 'ES Score',
  RC_SCORE: 'RC Score',
  DI_SCORE: 'DI Score',
  TR_SCORE: 'TR Score',
};

const superProps = {
  ACCESS_LEVEL: 'Access Level',
  CURRENT_LOCATION: 'Current Location',
  FIRST_TIME: 'First Time',
  FREE_OR_PAID: 'Free or Paid',
  HOME_COUNTRY: 'Home Country',
  JOB_TYPE: 'Job Type',
  ORGANIZATION_NAME: 'Organization Name',
  ORGANIZATION_ID: 'Organization Id',
  ORGANIZATION_TYPE: 'Organization Type',
  TRIAL_USER_TYPE: 'Trial User Type',
};

const peopleProfileProps = {
  EMAIL: '$email',
  FIRST_NAME: '$first_name',
  LAST_NAME: '$last_name',
};

const peopleProps = {
  ACCESS_CODES_REDEEMED: 'Access Codes Redeemed',
  BRAZE_EXTERNAL_ID: '$braze_external_id',
  CURRENT_LOCATION: 'Current Location',
  BLOCK_BUTTONS_CLICKED: 'Block Buttons Clicked',
  ENTERPRISE: 'Enterprise',
  FIRST_RETURN_VISIT_DATE: 'First Return Visit Date',
  GSP_COMPLETED: 'GSP Completed',
  GSP_DATE_COMPLETED: 'GSP Date Completed',
  GSP_LANGUAGE: 'GSP Language',
  HOME_COUNTRY: 'Home Country',
  JOB_TYPE: 'Job Type',
  LAST_LOGIN_DATE: 'Last Login Date',
  LAST_SURVEY_ITEM_SUBMITTED: 'Last Survey Item Submitted',
  NUMBER_COMPARISONS_CREATED: 'Number of Comparisons Created',
  NUMBER_CULTURE_PAGES_VISITED: 'Number of culture pages visited',
  NUMBER_GSPS_COMPLETED: 'Number of GSPs Completed',
  NUMBER_INVITATIONS_ACCEPTED: 'Number of Invitations Accepted',
  NUMBER_INVITATIONS_SENT: 'Number of Invitations Sent',
  NUMBER_OF_ADVICE_TOPICS_VIEWED: 'Number of Advice Topics Viewed',
  NUMBER_OF_ASMT_GROUPS_AS_MEMBER: 'Number of Assessment Groups as a Member',
  NUMBER_OF_ASMT_GROUPS_CREATED: 'Number of Assessment Groups Created',
  NUMBER_TEAMS_AS_MEMBER: 'Number of Teams as a Member',
  NUMBER_TEAMS_CREATED: 'Number of Teams Created',
  ORGANIZATION_NAME: 'Organization Name',
  ORGANIZATION_ID: 'Organization Id',
  PRODUCT_UPDATES: 'Product Updates',
  PURCHASES: 'Purchases',
  REGISTRATION_DATE: 'Registration Date',
  SESSIONS_ATTENDED: 'Sessions Attended',
  SIGN_UP_DATE: 'Signup Date',
  TOTAL_LOGINS: 'Total Logins',
};

const MILLISECONDS_DELAY = 300;
const resolveAfterTimeout = new Promise(resolve => setTimeout(resolve, MILLISECONDS_DELAY));

const getFreeOrPaid = accessLevels => {
  if (accessLevels.length === 0 || accessLevels.includes('free')) {
    return 'Free';
  }
  return 'Paid';
};

const checkOrgNames = organizations => {
  if (organizations.length) {
    return organizations.map(org => org.name).join(', ');
  }
  return null;
};

const checkOrgIds = organizations => {
  if (organizations.length) {
    return organizations.map(org => org.id).join(', ');
  }
  return null;
};

const getHighestAccessLevel = accessLevels => {
  if (accessLevels.includes('premium')) {
    return 'Premium';
  }
  if (accessLevels.includes('pro')) {
    return 'Pro';
  }
  return 'Free';
};

const getAuthType = connection => {
  if (connection === 'auth0') {
    return 'EmailPw';
  }
  return 'SSO';
};

const booleanAsString = boolValue => capitalize(ldToString(boolValue));

const fire = mixpanelCall => {
  // noop if not in a browser window with mixpanel
  if (window && window.mixpanel) {
    mixpanelCall();
  }
};

const incrementSuperProp = (superPropertyName, howMany = 1) => {
  fire(() => {
    const updateObject = {};
    // read current value from the mixpanel cookie
    const currentValue = window.mixpanel.get_property(superPropertyName);
    if (currentValue && typeof (currentValue) === 'number') {
      updateObject[superPropertyName] = currentValue + howMany;
    } else {
      updateObject[superPropertyName] = howMany;
    }
    window.mixpanel.register(updateObject);
  });
};

const tracker = {

  track: (eventName, eventProperties) => fire(
    () => window.mixpanel.track(eventName, eventProperties),
  ),

  register: superProperties => fire(
    () => window.mixpanel.register(superProperties),
  ),

  people: {
    set: peopleProperties => fire(
      () => {
        window.mixpanel.people.set(peopleProperties);
      },
    ),
    set_once: peopleProperties => fire(
      () => {
        window.mixpanel.people.set_once(peopleProperties);
      },
    ),
    increment: peopleProperty => fire(
      () => window.mixpanel.people.increment(peopleProperty),
    ),
    union: (peopleProperty, value) => fire(
      () => window.mixpanel.people.union(peopleProperty, value),
    ),
  },

  identify: userId => {
    fire(() => {
      window.mixpanel.identify(userId);
    });
  },

  reset: () => fire(
    () => window.mixpanel.reset(),
  ),

  booleanAsString,
  checkOrgNames,
  checkOrgIds,
  eventNames,
  eventProps,
  getAuthType,
  getFreeOrPaid,
  getHighestAccessLevel,
  incrementSuperProp,
  peopleProfileProps,
  peopleProps,
  resolveAfterTimeout,
  superProps,
};

export default tracker;
