/* eslint-disable new-cap */
import React from 'react';
import Parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import PropTypes from 'prop-types';

import { Link } from 'react-router';
import Avatar from '../../../common/Avatars/Avatar';
import Icon from '../../../common/Icon';

const TipCard = ({
  hasPremiumAccess,
  showTip,
  trackClickLink,
  tip,
}) => (
  <div className="w-full h-auto p-5 bg-white 3xl:max-w-lg sm:p-6 rounded-xl">
    <div className="relative flex items-center justify-start gap-2 px-2 pb-4 mb-4 border-b-2 border-b-stone-400">
      <h3 className="m-0 font-serif text-xl font-semibold leading-snug tracking-wider md:text-2xl text-charcoal-900" id="slide-over-title">
        Stay Curious
      </h3>
      <div className="absolute w-16 h-16 rounded-full top-2 right-2 md:w-20 md:h-20 bg-ivory-400">
        <Icon
          icon="stay-curious"
          iconColor="black"
          className="w-12 h-12 mx-auto my-2 md:w-16 md:h-16"
        />
      </div>
    </div>
    <div className={`flex flex-wrap gap-4 xs:flex-nowrap ${showTip ? 'opacity-100' : 'opacity-0'} transform transition-opacity ease-in duration-500`}>
      {tip.guidesLink && (
        <Avatar
          isCulture
          avatarUrl={`${tip.flagIcon}`}
          avatarImgAlt={`${tip.title} flag`}
          size="lg"
          className="!min-w-20 !min-h-20 rounded-md drop-shadow-xl"
        />
      )}
      <div className="flex flex-col">
        <h5 className="mb-2 text-lg">{tip.title}</h5>
        <div className="[&>p]:text-sm [&>p]:md:text-base">
          {Parser(DOMPurify.sanitize(tip.text))}
        </div>
      </div>
    </div>
    <div className="flex justify-end">
      {hasPremiumAccess ? (
        <a
          href={`${tip.guidesLink}${tip.tipLink}`}
          onClick={() => trackClickLink(tip)}
        >
          Learn more
        </a>
      ) : (<Link to="/dashboard?purchase&plan=starter-6mopaid">Learn more</Link>)}
    </div>
  </div>
);

TipCard.propTypes = {
  hasPremiumAccess: PropTypes.bool,
  showTip: PropTypes.bool,
  trackClickLink: PropTypes.func,
  tip:
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      text: PropTypes.string,
      tipLink: PropTypes.string,
      flagIcon: PropTypes.string,
      guidesLink: PropTypes.string,
      tipType: PropTypes.string,
    }),
};

TipCard.defaultProps = {
  hasPremiumAccess: true,
  showTip: true,
  trackClickLink: () => {},
  tip: {},
};

export default TipCard;
