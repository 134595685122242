import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';

const BackToTopButton = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function BackToTop({ className, isIntersecting }, ref) {
    const scrollToWindowTop = () => window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const scrollToRef = () => ref.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const wrapperClasses = classNames({
      '!fixed p-2 m-auto right-8 md:right-9 lg:right-auto lg:left-1/2 bg-white rounded-md shadow-lg border transition-all duration-200 hover:bg-charcoal-50 z-[400]': true,
      'bottom-40 sm:bottom-16 lg:bottom-16': isIntersecting,
      'bottom-2 sm:bottom-2 lg:bottom-4': !isIntersecting,
    });

    return (
      <div className={twMerge(wrapperClasses, className)}>
        <button
          type="button"
          className="flex items-center justify-center w-auto gap-2 p-0 m-auto bg-transparent rounded-lg cursor-pointer"
          onClick={ref ? scrollToRef : scrollToWindowTop}
        >
          <svg className="stroke-charcoal-900" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 20.6621C4.01099 18.933 2 15.7013 2 11.9999C2 6.47703 6.47715 1.99988 12 1.99988C17.5228 1.99988 22 6.47703 22 11.9999C22 15.7013 19.989 18.933 17 20.6621M16 11.9999L12 7.99995M12 7.99995L8 11.9999M12 7.99995V21.9999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

          <p className="mb-0 text-sm">Back to Top</p>
        </button>
      </div>
    );
  });

BackToTopButton.propTypes = {
  className: PropTypes.string,
  isIntersecting: PropTypes.bool.isRequired,
};

BackToTopButton.defaultProps = {
  className: null,
};

export default BackToTopButton;
