import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Link } from 'react-router';
import Table from '../../common/Table';
import Avatar from '../../common/Avatars/Avatar';
import Badges from '../../common/Badges';
import Button from '../../common/Button';
import Icon from '../../common/Icon';

const HomePageTable = ({
  displayedSessions,
  register,
  handleOpenSlider,
}) => (
  <Table
    className="min-w-full divide-y divide-gray-300"
    emptyMessage="There are no scheduled upcoming sessions at this time."
    columns={[
      {
        title: 'Topic',
        cellStyle: 'whitespace-normal lg:whitespace-nowrap pr-2 pl-3 py-4 md:pl-4 md:pr-3 text-sm font-medium text-gray-900 cursor-pointer',
        field: 'name',
        render: rowData => (
          <div>
            <div className="line-clamp-2 text-wrap">
              <Link
                to={`${process.env.SITE_URL}/aperian-live/program/${rowData.courseId}`}
              >
                {rowData.name}
              </Link>
            </div>
            <div className="flex flex-col gap-1 mt-1 font-normal text-gray-500 md:hidden md:mt-0">
              <div>
                {rowData.date} {rowData.time}
              </div>
              <div className="flex items-center gap-2">
                <Avatar
                  className="w-12 h-auto rounded-full bg-gray-50"
                  avatarImgAlt={rowData.facilitator}
                  avatarUrl={rowData.facilitatorAvatarUrl}
                  isNavigation
                />
                {rowData.facilitator}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'Tags',
        headerStyle: 'hidden lg:table-cell py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
        cellStyle: 'hidden lg:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500',
        field: 'tags',
        render: rowData => {
          const truncatedTags = rowData?.tags?.slice(0, 3);
          return (
            <Badges
              badges={truncatedTags}
              size="small"
              color="green"
            />
          );
        },
      },
      {
        title: 'Date',
        headerStyle: 'hidden md:table-cell py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
        cellStyle: 'hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500',
        field: 'dateTime',
        render: rowData => rowData.date,
      },
      {
        title: 'Time',
        headerStyle: 'hidden md:table-cell py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
        cellStyle: 'hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500',
        field: 'time',
        render: rowData => rowData.time,
      },
      {
        title: 'Facilitator',
        headerStyle: 'hidden md:table-cell py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
        cellStyle: 'hidden md:table-cell px-3 py-4 text-sm text-gray-500',
        field: 'facilitator',
        render: rowData => (
          <div className="flex items-center gap-2">
            <Avatar
              className="w-12 h-auto rounded-full bg-gray-50"
              avatarImgAlt={rowData.facilitator}
              avatarUrl={rowData.facilitatorAvatarUrl}
              isNavigation
            />
            {rowData.facilitator}
          </div>
        ),
      },
      {
        title: 'Details',
        headerStyle: 'hidden',
        cellStyle: 'table-cell h-full whitespace-nowrap pl-2 pr-4 md:px-3 py-4 text-sm text-gray-500',
        render: rowData => (
          <Button
            className="border-0 lg:border px-0 md:px-3.5 w-full"
            isExtraSmall
            variant="secondary"
            onClick={() => handleOpenSlider(rowData.cohortCode, 'table')}
          >
            <Icon
              className="block w-7 h-7 lg:hidden"
              icon="info"
              iconColor="dark-gray"
            />
            <div className="hidden lg:block">Details</div>
          </Button>
        ),
      },
      {
        title: 'Button',
        headerStyle: 'hidden',
        cellStyle: 'whitespace-nowrap pl-2 pr-3 md:px-3 py-4 text-sm text-gray-500',
        render: ({
          isRegistered,
          cohortCode,
          dateTime,
        }) => {
          const todaysDate = format(new Date(), 'yyyy-MM-dd hh:mm');
          const sessionDate = format(new Date(dateTime), 'yyyy-MM-dd hh:mm');
          return (
            <div>
              { todaysDate > sessionDate
                ? <p className="mb-0 text-sm font-semibold tracking-wider px-3.5 py-2 text-charcoal-200">Closed</p>
                : (
                  <Button
                    onClick={() => register(
                      cohortCode,
                      'home page table',
                    )}
                    isExtraSmall
                    className="w-full"
                    variant="primary"
                    disabled={!!isRegistered}
                  >
                    {isRegistered ? 'Registered' : 'Register'}
                  </Button>
                )}
            </div>
          );
        },
      },
    ]}
    data={displayedSessions}
    options={{
      headersStyle: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
      bodyStyle: 'divide-y',
    }}
  />
);

HomePageTable.propTypes = {
  displayedSessions: PropTypes.arrayOf(
    PropTypes.shape({
      cohortCode: PropTypes.string,
      date: PropTypes.string,
      dateTime: PropTypes.string,
      description: PropTypes.string,
      duration: PropTypes.number,
      facilitator: PropTypes.string,
      facilitatorAvatarUrl: PropTypes.string,
      isRegistered: PropTypes.bool,
      name: PropTypes.string,
      nameInternal: PropTypes.string,
      prework: PropTypes.arrayOf(
        PropTypes.string,
      ),
      tags: PropTypes.arrayOf(
        PropTypes.string,
      ),
      time: PropTypes.string,
      userJoinUrl: PropTypes.string,
    }),
  ).isRequired,
  register: PropTypes.func.isRequired,
  handleOpenSlider: PropTypes.func.isRequired,
};

export default HomePageTable;
