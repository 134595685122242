import React, { useContext } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import { APIContext } from '../../../context/API';

import { trackActivityCompletion } from '../../../actions/skills';

import { trackTeamProfileActivityViewed } from '../../../lib/tracker/teams';
import { trackSkillActivityClick } from '../../../lib/tracker/skills';

import { VIEW_THE_GSP_TEAM_PROFILE_ACTIVITY } from '../activityConstants';

const CustomLink = ({
  activityName,
  activityLink,
  activityCode,
  className,
  isTrial,
  skillName,
  children,
}) => {
  const { apiService } = useContext(APIContext);
  const isExternal = activityLink?.startsWith('http') || activityLink?.startsWith('https');
  const isGSLearning = isExternal && activityLink.includes('gslearning');
  const isCulture = activityLink === '/guides';
  const isVideo = activityLink === '/profile';

  const trackActivity = code => {
    if (code === VIEW_THE_GSP_TEAM_PROFILE_ACTIVITY) {
      trackActivityCompletion(apiService, code);
      trackTeamProfileActivityViewed();
    }
    trackSkillActivityClick(apiService, activityName, skillName);
  };

  if (isExternal || (isVideo && activityCode.includes('video'))) {
    if (isTrial && isGSLearning) {
      return (<span className={`text-gray-700 cursor-default ${isTrial && 'm-[0.35rem]'}`}>{children}</span>);
    }
    return (
      <a
        onClick={() => trackActivity(activityCode)}
        href={isVideo ? `${activityLink}?videoTrackingId=${activityCode.replace(/watch_|_video/g, '')}#profile-video` : activityLink}
        className={`${className} ${isTrial && 'm-[0.35rem]'}`}
        key={activityCode}
        target={!isVideo ? '_blank' : ''}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return (
    <Link to={isCulture ? `${activityLink}?activity=${activityCode}` : activityLink} key={activityCode} className={`${className} ${isTrial && 'm-[0.35rem]'}`} onClick={() => trackActivity(activityCode)}>
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  activityName: PropTypes.string.isRequired,
  activityLink: PropTypes.string.isRequired,
  activityCode: PropTypes.string.isRequired,
  isTrial: PropTypes.bool.isRequired,
  skillName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomLink;
