export async function getTips(apiService) {
  return apiService.get('dashboard/tips');
}

export async function createTipAction(apiService, data) {
  return apiService.post('tips', data);
}

export async function searchTipsAction(apiService, name) {
  return apiService.get(`tips/search?term=${name}`);
}

export async function updateTipAction(apiService, id, data) {
  return apiService.put(`tips/${id}`, data);
}

export async function deleteTipAction(apiService, id) {
  return apiService.delete(`tips/${id}`);
}
