import React, {
  cloneElement,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { format, isFuture } from 'date-fns';
import PropTypes from 'prop-types';

import { RouterContext } from '../../../../context/Router';
import { APIContext } from '../../../../context/API';
import { ModalContext } from '../../../../context/Modal';

import withServerSideData from '../../../../HOC/withServerSideData';

import EditableField from '../../../EditableField';
import Tabs from '../../../Tabs';
import ToggleSwitch from '../../../common/ToggleSwitch';
import Badges from '../../../common/Badges';
import Button from '../../../common/Button';
import Table from '../../../common/Table';
import ConfirmModal from '../../../common/Modal/ConfirmModal';
import Notification from '../../../Notifications/Notification';
import GlobeSmartModal from '../../../GlobeSmartModal';

import UpdateOrganizationAction from '../../../../actions/organizations/updateOrganization';
import { BadRequestError } from '../../../../lib/errors';
import orgTrialConversionAction from '../../../../actions/organizations/orgTrialConversion';

const tabList = id => ([
  {
    name: 'Authorizations',
    link: `/administration/organizations/${id}/authorizations`,
  },
  {
    name: 'Add Users',
    link: `/administration/organizations/${id}/add-users`,
  },
  {
    name: 'Owners',
    link: `/administration/organizations/${id}/owners`,
  },
  {
    name: 'Dashboard Blocks',
    link: `/administration/organizations/${id}/dashboard/blocks`,
  },
  {
    name: 'Dashboard Message',
    link: `/administration/organizations/${id}/dashboard/message`,
  },
  {
    name: 'Deletion Options',
    link: `/administration/organizations/${id}/delete-users`,
  },
]);

const OrganizationDetail = ({ initialData, location, children, params: { id } }) => {
  const { router } = useContext(RouterContext);
  const { apiService } = useContext(APIContext);
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    ...initialData,
  });

  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (!children) router.push(`/administration/organizations/${id}/authorizations`);
  }, []);

  useEffect(() => {
    let timeOut;
    if (notification) {
      window.scroll(0, 0);
      timeOut = setTimeout(() => setNotification(null), 3000);
    }
    return () => clearTimeout(timeOut);
  }, [notification]);

  async function handleUpdate(newData) {
    const updatedOrgData = { ...state, ...newData };
    try {
      await new UpdateOrganizationAction(apiService).execute(state.id, updatedOrgData);
      setState(newData);
      setNotification({ type: 'confirm', message: 'Updated successfully' });
    } catch (err) {
      let { message } = err;
      if (err instanceof BadRequestError) {
        const detail = err.reason.error.details[0];
        const key = Object.keys(detail)[0];
        message = detail[key];
      }
      setNotification({ type: 'warning', message: `There was an error updating the organization:<br>${message}` });
    }
  }

  const handleConvertTrialOrg = async () => {
    try {
      const { users } = await orgTrialConversionAction(apiService, id);
      if (users) {
        handleCloseModal();
        setNotification({ type: 'success', message: 'This organization has been converted from Trial to Licensed.' });
      }
    } catch (error) {
      handleCloseModal();
      setNotification({ type: 'warning', message: 'There was an error converting the organization.' });
    }
  };

  const handleOpenConfirmModal = () => handleOpenModal({
    content: (
      <ConfirmModal
        confirmButtonText="Yes, Convert"
        onCancel={handleCloseModal}
        onConfirm={handleConvertTrialOrg}
        title="Convert Trial to Licensed"
        confirmText="CONVERT"
      ><p className="text-base">Confirm conversion by typing <b>CONVERT</b> in the box.</p>
      </ConfirmModal>),
  });

  return (
    <>
      <h2 className="flex items-center gap-3 mb-6">
        <Badges
          color="secondary"
          badges={state.isTrial && !state.trialData?.isConverted ? ['Trial'] : []}
          size="large"
        />
      </h2>
      {notification ? <Notification {...notification} /> : null}
      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4 className="mb-4 text-2xl">Profile</h4>
          <div className="flex flex-col gap-2 mb-6">
            <h4 className="mb-0 text-xl">{state.name}</h4>
            <p className="mb-0 font-sans text-sm font-normal leading-tight"><b>Stripe ID:</b> {state.stripeId || 'None'}</p>
            <p className="mb-0 font-sans text-sm font-normal leading-tight"><b>Created:</b> {format(new Date(state.created), 'MM-dd-yyyy @ h:mm')}</p>
            <p className="mb-0 font-sans text-sm font-normal leading-tight"><b>Total Members:</b> {state.memberCount}</p>
            <p className="mb-0 font-sans text-sm font-normal leading-tight"><b>Members With Profiles:</b> {state.membersWithProfiles || 0}</p>
            {state.contentControllerATUCount ? (
              <p className="mb-0 font-sans text-sm font-normal leading-tight">
                Content Controller ATU: {state.contentControllerATUCount}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col gap-4 md:col-span-4">
          <EditableField
            name="name"
            label="Name"
            value={state.name}
            onUpdate={handleUpdate}
          />
          <EditableField
            name="displayName"
            label="Display Name"
            value={state.displayName}
            onUpdate={handleUpdate}
          />
          <EditableField
            name="note"
            label="Note"
            value={state.note}
            onUpdate={handleUpdate}
            maxLength={255}
          />
          <EditableField
            name="atuPlan"
            label="ATU Plan"
            inputType="number"
            value={state.atuPlan}
            onUpdate={handleUpdate}
          />
          <EditableField
            name="licenseStartDate"
            label="License Start Date"
            inputType="date"
            value={state.licenseStartDate}
            onUpdate={handleUpdate}
            allowPreviousDates
          />
          <EditableField
            name="databoxEmbedLink"
            label="Databox Embed Link"
            value={state.databoxEmbedLink}
            onUpdate={handleUpdate}
          />
        </div>
      </div>
      {state.isTrial ? (
        <>
          <hr className="my-8 border border-stone-400" />
          <div className="grid gap-2 md:grid-cols-6">
            <div className="[&>p]:font-normal [&>p]:text-sm [&>p]:mb-2 md:col-span-2">
              <h4>Organization Trial Details</h4>
              {state.trialData?.isConverted
                ? <p><b>Converted Date: </b>{format(new Date(state.trialData.convertedDate), 'MMM dd yyyy')}</p>
                : null}
              <p><b>Track Selected:</b> {state.trialData.trackName}</p>
              <p><b>Created:</b> {format(new Date(state.trialData.startDate), 'MMM dd yyyy')}</p>
              <p><b>End date:</b> {format(new Date(state.trialData.endDate), 'MMM dd yyyy')}</p>
              <p><b>Status:</b> {isFuture(new Date(state.trialData.endDate)) ? 'Active' : 'Expired'}</p>
            </div>
            <div className="flex flex-col overflow-hidden md:col-span-4">
              <div className="flex items-center justify-between mb-4">
                <h4 className="mb-0">Members</h4>
                {state.trialData.isConverted
                  ? <Badges color="secondary" badges={['Converted']} size="large" />
                  : (
                    <Button
                      filledColor="secondary"
                      onClick={handleOpenConfirmModal}
                    >Convert to License
                    </Button>
                  )}
              </div>
              <div className="overflow-x-auto">
                <Table
                  className="w-full text-sm divide-y divide-gray-300 text-charcoal-900"
                  columns={[
                    {
                      title: 'Name',
                      cellStyle: 'py-4 pl-0 pr-3 font-medium md:pl-4 whitespace-nowrap',
                      render: rowData => `${rowData?.firstName} ${rowData?.lastName}`,
                    },
                    {
                      title: 'Email',
                      cellStyle: 'py-4 pl-0 pr-3 md:pl-4 whitespace-nowrap',
                      field: 'email',
                    },
                  ]}
                  data={state.trialData.orgMembers}
                  options={{
                    rowStyle: 'even:bg-ivory-500',
                    headersStyle: 'py-3.5 pl-0 md:pl-4 pr-3 text-left font-semibold',
                    bodyStyle: 'divide-y divide-gray-200',
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
      <hr className="my-8 border border-stone-400" />
      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h4 className="mb-4 text-2xl">Organization Features</h4>
        </div>
        <div className="flex flex-col gap-4 md:col-span-4">
          <div className="flex justify-between">
            <h5>Organization Compare</h5>
            <ToggleSwitch
              id={`displayOrgAverage-${id}`}
              name="displayOrgAverage"
              checked={state.displayOrgAverage}
              onChange={e => handleUpdate({ [e.target.name]: e.target.checked })}
            />
          </div>
          <div className="flex justify-between">
            <h5>Share Profile Link</h5>
            <ToggleSwitch
              id={`allowShareProfileLink-${id}`}
              name="allowShareProfileLink"
              checked={state.allowShareProfileLink}
              onChange={e => handleUpdate({ [e.target.name]: e.target.checked })}
            />
          </div>
          <div className="flex justify-between">
            <h5>Share Profile to LinkedIn</h5>
            <ToggleSwitch
              id={`allowShareProfileLinkedIn-${id}`}
              name="allowShareProfileLinkedIn"
              checked={state.allowShareProfileLinkedIn}
              onChange={e => handleUpdate({ [e.target.name]: e.target.checked })}
            />
          </div>
          <div className="flex justify-between">
            <h5>Share Skills Badge</h5>
            <ToggleSwitch
              id={`allowShareSkillsBadge-${id}`}
              name="allowShareSkillsBadge"
              checked={state.allowShareSkillsBadge}
              onChange={e => handleUpdate({ [e.target.name]: e.target.checked })}
            />
          </div>
          <div className="flex justify-between">
            <h5>Skills Tracking</h5>
            <ToggleSwitch
              id={`allowSkillsTracking-${id}`}
              name="allowSkillsTracking"
              checked={state.allowSkillsTracking}
              onChange={e => handleUpdate({ [e.target.name]: e.target.checked })}
            />
          </div>
          <div className="flex justify-between">
            <h5>Show Profile and GS Guides</h5>
            <ToggleSwitch
              id={`allowGSPShowing-${id}`}
              name="allowGSPShowing"
              checked={state.allowGSPShowing}
              onChange={e => handleUpdate({ [e.target.name]: e.target.checked })}
            />
          </div>
          <div className="flex justify-between">
            <h5>Show Learning Modules Home</h5>
            <ToggleSwitch
              id={`allowLearningModules-${id}`}
              name="allowLearningModules"
              checked={state.allowLearningModules}
              onChange={e => handleUpdate({ [e.target.name]: e.target.checked })}
            />
          </div>
          <div className="flex justify-between">
            <h5>Show Dashboard Tips</h5>
            <ToggleSwitch
              id={`allowTipsShowing-${id}`}
              name="allowTipsShowing"
              checked={state.allowTipsShowing}
              onChange={e => handleUpdate({ [e.target.name]: e.target.checked })}
            />
          </div>
        </div>
      </div>
      <hr className="my-8 border border-stone-400" />
      <h4 className="mb-4 text-2xl">Organization Options</h4>
      <Tabs tabs={tabList(id)} location={location} />
      {/* So as not to do an extra API call when rendering the OrganizationDashboard Layout */}
      {/* we're passing the props from the parent (OrganizationDetail) to the children. */}
      {/* We should refactor the other 3 child components in the future */}
      {children ? cloneElement(children, {
        addNotification: setNotification,
        onUpdate: handleUpdate,
        message: state.message,
        orgName: state.name,
        auth0ConnectionId: state.auth0ConnectionId,
        contentControllerAccountId: state.contentControllerAccountId,
      }) : null}
      <GlobeSmartModal />
    </>
  );
};

OrganizationDetail.getAPIDataKey = () => 'organization';

OrganizationDetail.getData = (apiService, { id }) => apiService
  .get(`organizations/${id}`)
  .then(data => ({ organization: data.organization }));

OrganizationDetail.propTypes = {
  initialData: PropTypes.shape({
    auth0ConnectionId: PropTypes.string,
    allowShareProfileLinkedIn: PropTypes.number,
    allowShareProfileLink: PropTypes.number,
    allowSkillsTracking: PropTypes.number,
    allowGSPShowing: PropTypes.number,
    allowLearningModules: PropTypes.number,
    allowTipsShowing: PropTypes.number,
    allowShareSkillsBadge: PropTypes.number,
    created: PropTypes.string,
    displayName: PropTypes.string,
    displayOrgAverage: PropTypes.number,
    id: PropTypes.string,
    memberCount: PropTypes.number,
    membersWithProfiles: PropTypes.number,
    message: PropTypes.node,
    name: PropTypes.string,
    note: PropTypes.string,
    stripeId: PropTypes.string,
    updated: PropTypes.string,
    contentControllerAccountId: PropTypes.number,
    contentControllerATUCount: PropTypes.number,
  }).isRequired,
  children: PropTypes.node,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

OrganizationDetail.defaultProps = {
  children: null,
};

export default withServerSideData(OrganizationDetail);
