/* eslint-disable prefer-arrow-callback */
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import DropdownSelect from '../../../common/Dropdowns/DropdownSelect';
import LabelInput from '../../../common/LabelInput';
import TimePickerInput from '../../../common/TimePicker';

import { platforms, translations } from '../../../../lib/constants';

const EventCohortForm = forwardRef(
  function EventForm({
    formData,
    handleFormData,
    facilitators,
    topicOptions,
    formError,
  }, ref) {
    const timeRef = useRef(null);

    useEffect(() => {
      handleFormData({
        displayName: topicOptions?.at(0)?.value,
        name: null,
        facilitator: facilitators?.at(0)?.value.toString() || '',
        timezone: facilitators?.at(0)?.timezone || null,
        meetingPlatform: 'Zoom',
        language: 'en',
        eventDate: null,
        evaluationLink: null,
        org: null,
        linkExpiryDate: null,
      });
    }, []);

    const validateForm = () => {
      const isMissingEventDate = !formData.eventDate;
      if (isMissingEventDate) {
        return {
          eventDate: 'Please select a date and time for your event.',
        };
      }
      return null;
    };

    const resetForm = () => {
      handleFormData({
        displayName: topicOptions?.at(0)?.value,
        name: null,
        facilitator: facilitators?.at(0)?.value.toString() || '',
        timezone: facilitators?.at(0)?.timezone || null,
        meetingPlatform: 'Zoom',
        language: 'en',
        eventDate: null,
        evaluationLink: null,
        org: null,
        linkExpiryDate: null,
      });
    };

    useImperativeHandle(ref, () => ({
      validateForm,
      resetForm,
    }));

    const renderTimeZone = () => {
      const facilitatorValue = parseInt(formData?.facilitator, 10);
      const facilitatorObject = facilitators.find(obj => obj.value === facilitatorValue);
      if (facilitatorObject) {
        return facilitatorObject.timezone;
      }
      return null;
    };

    const handleChange = ({ target: { name, value } }) => {
      handleFormData({ [name]: value });
      if (name === 'facilitator') {
        const facilitatorValue = parseInt(value, 10);
        const facilitatorObject = facilitators.find(obj => obj.value === facilitatorValue);
        handleFormData({ timezone: facilitatorObject.timezone });
      }
    };

    const handleTimeChange = time => handleFormData({
      eventDate: time,
      linkExpiryDate: time,
    });

    return (
      <>
        <DropdownSelect
          id="displayName"
          name="displayName"
          labelText="Display Name"
          helperText="Select a description that will appear to cohort members."
          isRequired
          options={topicOptions}
          value={formData.displayName}
          onChangeValue={handleChange}
        />
        <div className="mb-4">
          <LabelInput
            id="name"
            name="name"
            labelType="text"
            isRequired
            labelText="Name (Internal)"
            value={formData.name}
            onChangeValue={handleChange}
            helperText="Enter an internal name for search indexing."
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <DropdownSelect
              id="facilitator"
              name="facilitator"
              labelType="text"
              labelText="Facilitator"
              helperText="Choose a facilitator for your event."
              isRequired
              options={facilitators}
              value={formData?.facilitator || ''}
              onChangeValue={handleChange}
            />
          </div>
          <div>
            <LabelInput
              id="timezone"
              name="timezone"
              labelType="text"
              isRequired
              labelText="Facilitator Timezone"
              value={formData.timezone || renderTimeZone()}
              onChangeValue={handleChange}
              isDisabled
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2">
          <div>
            <TimePickerInput
              ref={timeRef}
              id="eventDate"
              labelText="Event Time"
              handleIconClick={() => timeRef.current.setFocus()}
              value={formData.eventDate}
              handleTimeChange={handleTimeChange}
              onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
              onChangeValue={handleChange}
              showTimeSelect
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="yyyy-MM-dd hh:mm aa"
              helperText="Choose the time of your event in the facilitator's time zone."
              errorMessage={formError.eventDate}
            />

          </div>
          <div>
            <DropdownSelect
              id="language"
              name="language"
              labelType="text"
              labelText="Language"
              options={translations}
              value={formData.language || 'en'}
              onChangeValue={handleChange}
              isDisabled
              helperText="Choose the language for your event."
            />
          </div>
        </div>
        <div>
          <DropdownSelect
            id="meetingPlatform"
            name="meetingPlatform"
            labelType="text"
            labelText="Meeting Platform"
            options={platforms}
            value={formData.meetingPlatform}
            onChangeValue={handleChange}
            isDisabled
            helperText="Choose the meeting platform for your event."
          />
        </div>
        <LabelInput
          id="evaluationLink"
          name="evaluationLink"
          labelType="text"
          labelText="Evaluation Link"
          value={formData.evaluationLink}
          onChangeValue={handleChange}
          helperText="Enter the link to the post-evaluation survey"
        />
      </>
    );
  });

EventCohortForm.propTypes = {
  handleFormData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    eventDate: PropTypes.instanceOf(Date),
    evaluationLink: PropTypes.string,
    eventPrework: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
    eventTime: PropTypes.instanceOf(Date),
    facilitator: PropTypes.string,
    language: PropTypes.string,
    meetingPlatform: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
    timezone: PropTypes.string,
    name: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  facilitators: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      timezone: PropTypes.string,
    })),
  topicOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  formError: PropTypes.shape({
    eventDate: PropTypes.string,
    eventTime: PropTypes.string,
    eventDuration: PropTypes.string,
  }),
};

EventCohortForm.defaultProps = {
  formError: {},
  facilitators: [],
  topicOptions: [],
};

export default EventCohortForm;
