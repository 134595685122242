import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

import { fillColorMap } from '../index.js';

const StayCuriousIcon = ({ className, iconColor }) => (
  <svg width="360" height="360" viewBox="0 0 360 360" fill="none" className={`${className} ${fillColorMap[iconColor]}`} xmlns="http://www.w3.org/2000/svg">
    <path d="M171 18.5C119.3 18.5 77.2003 59.5 75.3003 110.7C75.2003 112.7 76.8003 114.3 78.8003 114.3H263.3C265.3 114.3 266.8 112.7 266.8 110.7C264.8 59.4 222.7 18.5 171 18.5Z" />
    <path d="M159.3 253L209.3 125.6C209.8 124.3 211.1 123.4 212.5 123.4H307.7C310.1 123.4 311.8 125.8 310.9 128.1L260.9 255.5C260.4 256.8 259.1 257.7 257.7 257.7H162.5C160 257.7 158.4 255.3 159.3 253Z" />
    <path d="M191.601 317.1C191.601 330.6 180.601 341.6 167.101 341.6C153.601 341.6 142.601 330.6 142.601 317.1C142.601 303.6 153.601 292.6 167.101 292.6C180.601 292.6 191.601 303.5 191.601 317.1Z" />
  </svg>
);

StayCuriousIcon.propTypes = iconPropTypes;
StayCuriousIcon.defaultProps = defaultIconProps;

export default StayCuriousIcon;
