import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { differenceInMinutes, minutesToMilliseconds } from 'date-fns';
import PropTypes from 'prop-types';

import { ToastContext } from '../../../context/Toast';

import CourseCatalogSubContainer from './CourseCatalogSubcontainer';
import CourseCatalogTable from './CourseCatalogTable';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import PopoverComponent from '../../common/Popover';
import ShareSessionPopover from '../ShareSessionPopover';

import { formatSessionDates } from '../../../lib/aperianLive';

const CourseCatalogContainer = ({
  sessionTopicData,
  copyShareableLink,
  join,
  register,
  isCopied,
  userId,
  organizations,
  hasAccess,
}) => {
  const { addToast, removeToast } = useContext(ToastContext);
  const [formattedSessions, setFormattedSessions] = useState(sessionTopicData.sessions);

  const sessionShareOptions = [
    {
      id: 1,
      name: 'Copy Link',
      onClick: copyShareableLink,
      icon: 'link',
      isCopy: true,
    },
  ];

  useEffect(() => {
    const findIsJoinEnabled = dateTime => {
      const now = new Date();
      const startTime = new Date(dateTime);
      const diffToStartTime = differenceInMinutes(startTime, now);
      const meetingStartsInLessThanTen = diffToStartTime <= 10;
      const meetingStartsInMoreThanTen = diffToStartTime > 10;
      const meetingStartsInLessThanThirty = diffToStartTime <= 30;

      if (meetingStartsInLessThanTen) {
        return true;
      }

      if (meetingStartsInMoreThanTen && meetingStartsInLessThanThirty) {
        setTimeout(() => {
          setFormattedSessions(formatSessionDates(sessionTopicData?.sessions)
            .map(session => ({
              ...session,
              isJoinEnabled: findIsJoinEnabled(session.dateTime),
            })));
        },
        minutesToMilliseconds(diffToStartTime - 10));
      }
      return false;
    };

    setFormattedSessions(formatSessionDates(sessionTopicData?.sessions)
      .map(session => ({
        ...session,
        isJoinEnabled: findIsJoinEnabled(session.dateTime),
      })));
  }, [sessionTopicData]);

  useEffect(() => {
    let toastId;
    if (formattedSessions.some(session => session.isJoinEnabled && session.isRegistered)) {
      toastId = uuidv4();
      addToast({
        id: toastId,
        content: (
          <div>
            <div className="flex items-start mb-2">
              <Icon icon="aperian-live" iconColor="gray" className="w-10 h-8" />
              <div className="ml-2">
                <h5 className="mb-0 font-serif text-base text-charcoal-900 md:text-xl">{sessionTopicData.name}</h5>
                <p className="mb-0 text-base"> Get ready for your session!</p>
              </div>
            </div>
            <div className="flex flex-col w-full gap-2">
              {formattedSessions.map(session => {
                if (session.isJoinEnabled && session.isRegistered) {
                  return (
                    <Button
                      key={session.cohortCode}
                      isExtraSmall
                      onClick={() => join(
                        session.cohortCode,
                        session.userJoinUrl,
                        'course catalog page',
                      )}
                      variant="primary"
                      className="ml-auto w-fit"
                      disabled={!session.isJoinEnabled}
                    >
                      Join Session
                    </Button>
                  );
                }
                return null;
              })}
            </div>
          </div>
        ),
      });
    }
    return () => {
      if (toastId) removeToast(toastId);
    };
  }, [formattedSessions]);

  return (
    <div className="bg-white rounded-lg">
      { hasAccess ? (
        <>
          <div className="flex flex-col justify-between w-full gap-8 px-8 pb-4 md:flex-row md:px-10">
            <div className="flex flex-col items-start justify-between w-full md:w-3/5 md:gap-4">
              <h3 className="pt-8 mb-0">
                {sessionTopicData.name}
              </h3>
              <div className="flex justify-end gap-2 ml-0 md:border-l md:ml-4 border-rust-500">
                <p className="mb-0 ml-0 text-base md:ml-2">
                  {sessionTopicData.description}
                </p>
              </div>
              <PopoverComponent
                position="right"
                content={(
                  <ShareSessionPopover
                    isCopied={isCopied}
                    profileShareOptions={sessionShareOptions}
                  />
        )}
                className="w-full md:w-max -top-[75%]"
                singleLineClassName="px-4 py-2"
                fullWidthAtMobile
                isStatic
              >
                <Button
                  isExtraSmall
                  variant="secondary"
                  trailingButtonIcon="share"
                  iconColor="primary"
                  className="self-end w-full"
                >
                  Share
                </Button>
              </PopoverComponent>
            </div>
            <div className="h-full min-h-full pt-8 md:w-2/5">
              <CourseCatalogSubContainer
                prework={sessionTopicData.prework}
                tags={sessionTopicData.tags}
                duration={sessionTopicData.duration}
              />
            </div>
          </div>
          <div className="px-8 pb-6 overflow-x-auto">
            <CourseCatalogTable
              formattedSessions={formattedSessions}
              sessionShareOptions={sessionShareOptions}
              register={register}
              userId={userId}
              organizations={organizations}
              duration={sessionTopicData.duration}
              name={sessionTopicData.name}
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center gap-2 p-8">
          <p className="text-base text-center">You are not currently licensing the full Aperian Live catalog.
            Learn more about Aperian Live <a href="https://aperian.com/aperian-live/" target="_blank" rel="noopener noreferrer">here</a>.
          </p>
          <Button
            isExtraSmall
            to="/dashboard"
            variant="primary"
            className="w-full mx-auto md:w-auto"
          >
            Return to Dashboard
          </Button>
        </div>
      )}
    </div>
  );
};

CourseCatalogContainer.propTypes = {
  sessionTopicData: PropTypes.shape({
    name: PropTypes.string,
    duration: PropTypes.number,
    description: PropTypes.string,
    prework: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        nameInternal: PropTypes.string,
        facilitator: PropTypes.string,
        facilitatorAvatarUrl: PropTypes.string,
        dateTime: PropTypes.string,
        cohortCode: PropTypes.string,
        isRegistered: PropTypes.bool,
        userJoinUrl: PropTypes.string,
      })),
  }),
  join: PropTypes.func,
  register: PropTypes.func,
  copyShareableLink: PropTypes.func,
  isCopied: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  hasAccess: PropTypes.bool.isRequired,
};

CourseCatalogContainer.defaultProps = {
  sessionTopicData: {},
  join: () => {},
  register: () => {},
  copyShareableLink: () => {},
  isCopied: false,
  organizations: [],
};

export default CourseCatalogContainer;
