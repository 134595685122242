import React from 'react';
import Button from '../../../common/Button';

const PreFooter = () => (
  <div className="max-w-5xl mx-auto mb-10 md:px-8 xl:mb-12">
    <p className="mb-8 text-base text-center">
      You have no additional teams.
    </p>
    <div className="text-center">
      <h3 className="font-serif font-semibold text-2xl md:text-[2rem] text-center my-8">
        Improve Teamwork with the GlobeSmart Profile
      </h3>
      <p className="my-8 font-sans text-base font-light md:text-xl">
        Looking for a deeper understanding of your profiles?
        Use the <a href={`${process.env.AWS_APG_RESOURCES_HOST}mod/gspfit/`} target="_blank" rel="noopener noreferrer">facilitation guide</a> to improve team effectiveness.
      </p>
      <Button
        variant="primary"
        to="/profile/teams/new"
        className="block m-2 md:inline-block"
        trailingButtonIcon="arrow-right"
      >
        Create a Team
      </Button>
      <Button
        variant="primary"
        to="/profile/connections"
        className="block m-2 md:inline-block"
        trailingButtonIcon="arrow-right"
      >
        Manage Connections
      </Button>
    </div>
  </div>
);

export default PreFooter;
