import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import withServerSideData from '../../HOC/withServerSideData';

import { CurrentUserContext } from '../../context/CurrentUser';

import PageHeader from '../../components/PageHeader';
import CircularProgress from '../../components/common/CircularProgress';
import Icon from '../../components/common/Icon';
import Badges from '../../components/common/Badges';
import CustomLink from './CustomLink';

import Celebration from './Celebration';

import { COOKIE_SKILLS_CELEBRATED, IS_PRODUCTION } from '../../lib/constants';
import Button from '../../components/common/Button';
import PopoverComponent from '../../components/common/Popover';
import ShareSkillsPopover from './ShareSkillsPopover';
import logger from '../../lib/logger';
import { APIContext } from '../../context/API';
import { trackShareSkillBadge } from '../../lib/tracker/skills';

const MySkills = ({ initialData: { skills } }) => {
  const { currentUser: {
    isTrial,
    allowShareSkillsBadge,
    userid,
  } } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const [celebratedSkill, setCelebratedSkill] = useState(null);
  const [cookies, setCookies] = useCookies();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timeout;
    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, 6000);
    }
    return () => clearTimeout(timeout);
  }, [isCopied]);

  const checkSkillsCelebration = () => {
    const achievedSkills = skills.filter(skill => skill.isAchieved === 'complete');
    if (achievedSkills.length > 0) {
      if (cookies[COOKIE_SKILLS_CELEBRATED]) {
        const notYetCelebrated = achievedSkills
          .filter(skill => !cookies[COOKIE_SKILLS_CELEBRATED].includes(skill.name));

        if (notYetCelebrated.length > 0) {
          setCookies(COOKIE_SKILLS_CELEBRATED,
            [...cookies[COOKIE_SKILLS_CELEBRATED], notYetCelebrated.at(0).name],
            { path: '/', secure: IS_PRODUCTION });
          setCelebratedSkill(notYetCelebrated.at(0));
        }
      } else {
        setCookies(COOKIE_SKILLS_CELEBRATED,
          [achievedSkills.at(0).name],
          { path: '/', secure: IS_PRODUCTION });
        setCelebratedSkill(achievedSkills.at(0));
      }
    }
  };

  useEffect(() => {
    checkSkillsCelebration();
  }, []);

  const isNewActivity = completionDate => {
    const now = moment();
    const completion = moment(completionDate);
    return now.diff(completion, 'hours') < 48;
  };

  const copyShareableLink = async (skillId, skillName, shareId) => {
    let shareableLink = `${process.env.SITE_URL}/share/skill/`;
    if (shareId) {
      await trackShareSkillBadge(apiService, skillName, 'My Skills page');
      shareableLink += `${shareId}/${skillId}`;
    } else {
      const response = await apiService.post(`encrypt-user/${userid}/${skillId}`);
      await trackShareSkillBadge(apiService, skillName, 'My Skills page');
      shareableLink += `${response.shareId}/${skillId}`;
    }
    try {
      await navigator.clipboard.writeText(shareableLink);
      setIsCopied(true);
    } catch (err) {
      logger.error('Error copying link', err);
    }
  };

  const skillBadgeShareOptions = (skillId, skillName, shareId) => [
    {
      id: 1,
      name: 'Copy Link',
      onClick: () => { copyShareableLink(skillId, skillName, shareId); },
      icon: 'link',
      isCopy: true,
    },
  ];

  return (
    <div className="full-width">
      <PageHeader
        pageTitle="My Skills"
        icon="award"
        skipTarget="#my-skills"
      />
      <div className="mb-6 -mt-4 md:mb-10 lg:mb-14">
        <p className="w-full mb-0 text-sm lg:w-3/4">
          Enhance your professional capabilities and foster
          a more productive work environment
          by earning essential workplace skills.
        </p>
      </div>
      <section className="flex flex-col w-full m-auto xl:my-8 rounded-3xl">
        {skills?.map(skill => (
          <div key={skill.id} className="flex flex-wrap mb-4 bg-white rounded-lg md:relative" id={skill.name.toLowerCase().split(' ').join('-')}>
            <div className="w-full p-2 pb-0">
              <h3 className="pt-4 pl-4">{skill.name}</h3>
            </div>
            <div className="p-7 h-full space-x-0 md:pb-[4.5rem] lg:pb-[6.25rem] md:px-7 md:pt-6 md:space-x-1 lg:space-x-3 md:flex">
              <div className="flex w-full p-2 md:w-[22%]">
                <div className="relative flex flex-col items-center justify-center w-56 h-56 mx-auto mb-4 md:w-24">
                  <CircularProgress
                    total={skill.activities.length}
                    completed={skill.activities.filter(a => a.isCompleted).length}
                    className="w-40 mb-2 text-2xl md:w-36 lg:w-40"
                  />
                </div>
              </div>
              <div className="flex w-full p-2 md:w-[52%] lg:w-[50%] md:order-last" key={skill.id}>
                <ul className="grid grid-cols-1 gap-2 p-0 list-none md:p-5 lg:col-span-2 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                  {skill.activities.map(activity => (
                    <CustomLink
                      key={activity.activityCode}
                      activityLink={activity.activityLink}
                      activityName={activity.name}
                      activityCode={activity.activityCode}
                      className="no-underline"
                      isTrial={isTrial}
                      skillName={skill.name}
                    >
                      <li key={activity.activityCode} className="relative md:space-x-4 md:flex md:items-center hover:bg-stone-50">
                        <div className="flex items-center space-x-4">
                          <div className="relative flex flex-col items-center flex-shrink-0">
                            <img src={activity.isCompleted ? activity.completedIconImage : activity.iconImage} alt={activity.name} className={`w-12 mt-1 mb-1 ${isTrial && activity.activityLink.includes('gslearning') && 'grayscale'}`} />
                            {activity.isCompleted && isNewActivity(activity.completionDate)
                              && (
                                <span className="h-6 -mt-3">
                                  <Badges
                                    color="green"
                                    badges={['NEW']}
                                    size="small"
                                  />
                                </span>
                              )}
                            {activity.isCompleted ? (
                              <div className="absolute top-[-4px] right-[-4px] bg-green-500 border border-green-500 rounded-full w-4 h-4 flex items-center justify-center">
                                <Icon className="w-3 h-3" icon="check" iconColor="white" />
                              </div>
                            ) : null}
                          </div>
                          <span className="flex text-sm leading-5">{activity.name}</span>
                        </div>
                        {(activity.activityLink.includes('gslearning') && isTrial) && (
                          <span className="bottom-0 px-2 py-1 mt-1 text-sm leading-5 rounded-lg -mb-7 md:absolute text-charcoal-700 bg-ivory-400">
                            Not available during the trial
                          </span>
                        )}
                        {(activity.activityLink.includes('aperian-live')) && (
                          <span className="bottom-0 px-2 py-1 mt-1 text-sm leading-5 rounded-lg md:text-xs md:-mb-10 lg:-mb-8 md:absolute text-charcoal-700 bg-ivory-400">
                            * May take up to 24 hours to update
                          </span>
                        )}
                      </li>
                    </CustomLink>
                  ))}
                </ul>
              </div>
            </div>

            <div className="md:absolute right-0 top-0 bottom-0 flex justify-center p-6 h-full md:w-[30%] lg:w-[35%] xl:w-[30%] 2xl:w-[30%]">
              <div className="flex flex-col items-center justify-center w-full h-full p-4 m-1 text-left text-gray-700 border rounded-2xl bg-ivory-400">
                <div className="flex flex-col h-full">
                  <div className={`relative flex items-center justify-center md:w-32 md:h-32 lg:w-48 lg:h-48 w-48 h-48 mx-auto mb-4 ${skill.isAchieved !== 'complete' ? 'grayscale' : ''}`}>
                    <img src={skill.iconImage} alt={skill.name} className="max-w-full max-h-full" />
                  </div>
                  {skill.skillAchievedAt && (
                    <span className="mb-2 text-sm font-semibold leading-6 text-charcoal-900">
                      Achieved on {moment(skill.skillAchievedAt).format('MMMM Do, YYYY')}
                    </span>
                  )}
                  <span className="mb-4 text-sm font-normal leading-5">{skill.paragraphOne}</span>
                  <span className="text-sm font-normal leading-5">{skill.paragraphTwo}</span>
                </div>
                {allowShareSkillsBadge && (
                  <PopoverComponent
                    position="bottom"
                    content={(
                      <ShareSkillsPopover
                        isCopied={isCopied}
                        skillShareOptions={skillBadgeShareOptions(
                          skill.id,
                          skill.name,
                          skill.shareId,
                        )}
                      />
                    )}
                    isDisabled={skill.isAchieved !== 'complete'}
                    className="w-max"
                    singleLineClassName="px-4 py-2"
                    isStatic
                  >
                    <Button
                      isExtraSmall
                      variant="secondary"
                      trailingButtonIcon="share"
                      iconColor="primary"
                      className="flex justify-center mt-2"
                      disabled={skill.isAchieved !== 'complete'}
                    >
                      Share
                    </Button>
                  </PopoverComponent>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>
      <Celebration
        skill={celebratedSkill}
        onClose={checkSkillsCelebration}
        isCopied={isCopied}
        skillBadgeShareOptions={skillBadgeShareOptions}
      />
    </div>
  );
};

MySkills.getAPIDataKey = () => 'skills';
MySkills.getData = apiService =>
  apiService.get('skills').then(data => ({ skills: data }));

MySkills.propTypes = {
  initialData: PropTypes.shape({
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        iconImage: PropTypes.string,
        isAchieved: PropTypes.string,
        description: PropTypes.string,
        activities: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            iconImage: PropTypes.string,
            isCompleted: PropTypes.bool,
            activityCode: PropTypes.string,
            completionDate: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
};

export default withServerSideData(MySkills);
