import React from 'react';
import Button from '../../../common/Button';

const divCTAInfo = [
  {
    id: 1,
    headerText: 'Inclusive Actions Guide',
    bodyText: 'Use the GlobeSmart Profile dimensions to work in a more inclusive way',
    location: `${process.env.AWS_APG_RESOURCES_HOST}mod/gdia/`,
  },
  {
    id: 2,
    headerText: 'Improving Teamwork with the GlobeSmart Profile',
    bodyText: 'Learn how to leverage different work styles to improve teamwork.',
    location: `${process.env.AWS_APG_RESOURCES_HOST}mod/gspfit/#/`,
  },
];

const ProfileCompareCTA = () => (
  <div className="py-8 md:py-16 md:px-8 lg:py-24 lg:px-24 md:flex-row bg-slate-50">
    <div className="flex flex-col justify-evenly md:flex-row">
      {divCTAInfo.map(({ id, headerText, bodyText, location }) => (
        <div key={id} className="flex flex-col items-center max-w-[40rem] justify-between p-10 bg-white">
          <h3 className="text-center">{headerText}</h3>
          <p className="text-center">{bodyText}</p>
          <Button
            rel="noopener noreferrer"
            target="_blank"
            to={location}
            variant="primary"
            trailingButtonIcon="arrow-right"
          >View Guide
          </Button>
        </div>
      ))}
    </div>
  </div>
);

export default ProfileCompareCTA;
