import React, { Fragment, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import Icon from '../common/Icon';

const Toast = ({ id, content, dismiss, autoDismiss, delay, bgColor, toastLink, toastLinkText }) => {
  const [show, setShow] = useState(false);
  const [timer, setTimer] = useState(100);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let delayTimer;
    if (delay) {
      delayTimer = setTimeout(() => setShow(true), delay);
    } else {
      setShow(true);
    }
    return () => clearTimeout(delayTimer);
  }, []);

  useEffect(() => {
    if (timer < 0 && show) setShow(false);
  }, [timer]);

  useEffect(() => {
    let intervalTimer;
    const interval = 1000;
    const decrement = (interval / autoDismiss) * 100;
    if (show && autoDismiss) {
      if (!isPaused) {
        intervalTimer = setInterval(() => {
          setTimer(prevTimeLeft => {
            const newTimeLeft = prevTimeLeft - decrement;
            if (newTimeLeft < 0) {
              clearInterval(intervalTimer);
            }
            return newTimeLeft;
          });
        }, interval);
      }

      if (intervalTimer && isPaused) {
        clearInterval(intervalTimer);
      }
    }

    return () => clearInterval(intervalTimer);
  }, [show, isPaused]);

  const pauseTimer = () => setIsPaused(true);

  const resumeTimer = () => setIsPaused(false);

  return (
    <Transition
      appear
      show={show}
      as={Fragment}
      enter={twMerge('transform transition-all ease-in-out duration-500')}
      enterFrom="translate-x-full opacity-0"
      enterTo="translate-x-0 opacity-100"
      leave="transform transition ease-in-out duration-500"
      leaveFrom="translate-x-0 opacity-100"
      leaveTo="translate-x-full opacity-0"
      afterLeave={() => dismiss(id)}
    >
      <div
        onMouseEnter={pauseTimer}
        onMouseLeave={resumeTimer}
        className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5"
      >
        <div className={twMerge('p-4', bgColor)}>
          <div className="flex items-start">
            <div className="grow">
              {content}
            </div>
            <div className="flex flex-shrink-0 ml-4">
              <button
                id={id}
                type="button"
                onClick={() => setShow(false)}
                className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-rust-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <Icon icon="x-close" iconColor="gray" className={twMerge('w-3 h-3', bgColor)} />
              </button>
            </div>
          </div>
          {toastLink && toastLinkText && (
            <Link
              to={toastLink}
              className="flex justify-end ml-auto text-sm font-medium text-gray-700 underline bg-white rounded-md hover:text-rust-500 focus:outline-none"
            >
              {toastLinkText}
            </Link>
          )}

        </div>
        {autoDismiss
          ? (
            <div className="h-1.5 mt-2">
              <progress
                className="w-full"
                value={timer}
                max="100"
              >
                {timer}%
              </progress>
            </div>
          ) : null}
      </div>
    </Transition>
  );
};

Toast.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  dismiss: PropTypes.func.isRequired,
  autoDismiss: PropTypes.number,
  bgColor: PropTypes.string,
  delay: PropTypes.number,
  toastLink: PropTypes.string,
  toastLinkText: PropTypes.string,
};

Toast.defaultProps = {
  autoDismiss: null,
  bgColor: null,
  delay: null,
  toastLink: '',
  toastLinkText: '',
};

export default Toast;
