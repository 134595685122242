export async function manageAssessmentSurveyInstanceAction(apiService, data) {
  return apiService.post('assessments/survey-provider/webhook', data);
}

export async function submitAssessmentSurveyAnswerAction(apiService, data) {
  return apiService.post('assessments/survey-provider/submit-answer', data);
}

export async function setAssessmentSurveyLanguageAction(apiService, data) {
  return apiService.post('assessments/survey-provider/language-preference', data);
}
