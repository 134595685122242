import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Form } from '../../../../common/Form';
import DropdownSelect from '../../../../common/Dropdowns/DropdownSelect';
import LabelInput from '../../../../common/LabelInput';
import CK5EditorComponent from '../../../../CK5Editor';
import Button from '../../../../common/Button';
import { NotificationContext } from '../../../../../context/Notification';
import Notification from '../../../../Notifications/Notification';
import BackArrowLink from '../../../../common/BackArrowLink';
import TipCard from '../../../../Dashboard/Tips/TipCard';

const TipsForm = ({ formData, handleFormSubmit, buttonText, subHeader, isEditForm }) => {
  const {
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);

  const { tipDetails, types, cultures } = formData;

  const [tipsData, setTipsData] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    ...tipDetails,
    type: tipDetails
      ? types.find(type => type.value === tipDetails.type.value)
      : types[0],
    cultureProfile: tipDetails
      ? cultures.find(cult => cult.value === tipDetails.cultureProfile)
      : cultures[0],
  });

  const [previewData, setPreviewData] = useReducer((data, newData) => ({ ...data, ...newData }), {
    id: tipsData?.cultureProfile.value,
    title: tipsData?.title || '',
    text: tipsData?.text,
    tipLink: tipsData?.link,
    flagIcon: cultures.find(culture =>
      culture.value === tipsData.cultureProfile.value).flagIcon,
    guidesLink: cultures.find(culture =>
      culture.value === tipsData.cultureProfile.value).cultureGuideURL,
  });

  const [resetKey, setResetKey] = useState(0);
  const notificationRef = useRef(null);

  useEffect(() => {
    if (notificationMessage) {
      notificationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [notificationMessage]);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const handleChange = ({ target: { name, value } }) => {
    const option = (name === 'type' ? types : cultures)?.find(opt => opt.value.toString() === value);
    setTipsData({ [name]: option || value });
    if (name === 'link') {
      setPreviewData({ tipLink: option || value });
    } else if (name === 'cultureProfile') {
      setPreviewData({
        id: option.value,
        guidesLink: option.cultureGuideURL,
        flagIcon: option.flagIcon,
      });
    } else {
      setPreviewData({ [name]: option || value });
    }
  };

  const handleHTMLChange = text => {
    setTipsData({ text });
    setPreviewData({ text });
  };

  const clearForm = () => {
    const emptyTip = Object.keys(tipsData)
      .reduce((formObject, key) => {
        const form = formObject;
        form[key] = undefined;
        return form;
      }, {});

    setTipsData({
      ...emptyTip,
      type: types && types[0],
      cultureProfile: cultures && cultures[0],
    });
    setPreviewData({
      id: 0,
      title: '',
      text: '',
      tipLink: '',
      flagIcon: cultures[0].flagIcon,
      guidesLink: cultures[0].cultureGuideURL,
    });
    setResetKey(prevKey => prevKey + 1);
  };

  const onSubmit = e => {
    e.preventDefault();
    handleFormSubmit(tipsData, clearForm);
  };

  const defaultTextTemplate = '<p>Put your tip text here with any formatting you would like to use. Click "Source" if you would like to see the HTML output.</p>';

  return (
    <>
      {isEditForm && (
        <div className="flex mx-0 mt-0 mb-3 text-center align-self-start text-uppercase text-md">
          <BackArrowLink to="/administration/tips/search"> Back to Tips</BackArrowLink>
        </div>
      )}
      <Form bgColor="white" handleFormSubmit={onSubmit}>
        <Notification ref={notificationRef} {...notificationMessage} />
        <p className="max-w-3xl m-0 text-center">{subHeader}</p>
        <DropdownSelect
          id="type"
          name="type"
          labelType="text"
          labelText="Tip Type"
          options={types}
          isRequired
          value={tipsData.type?.value}
          onChangeValue={handleChange}
          isDisabled={!!tipDetails}
          helperText="Select the type of tip you are building."
        />
        <LabelInput
          id="title"
          name="title"
          labelType="text"
          isRequired
          labelText="Tip Title"
          value={tipsData?.title}
          onChangeValue={handleChange}
        />
        <span id="tip-text" className="block !mb-2 font-sans text-sm font-medium leading-6 text-left text-gray-700">Tip Text</span>
        <div>
          <CK5EditorComponent
            key={resetKey}
            savedHTML={tipsData.text || defaultTextTemplate}
            updateHTML={handleHTMLChange}
          />
        </div>
        <LabelInput
          id="link"
          name="link"
          labelType="text"
          isRequired
          value={tipsData?.link}
          onChangeValue={handleChange}
          labelText="Tip Link"
        />
        {tipsData?.type?.value === 1 && (
          <DropdownSelect
            id="culture-profile"
            name="cultureProfile"
            labelType="text"
            labelText="Cultures"
            options={cultures}
            isRequired
            value={tipsData?.cultureProfile?.value}
            onChangeValue={handleChange}
            isDisabled={!!tipDetails}
            helperText="Select the culture of the tip you are building."
          />
        )}
        <div className="p-3 mt-4 mb-4 rounded-lg bg-ivory-100">
          <h5 className="mb-8 text-lg text-left font-heading-5">Preview</h5>
          <TipCard tip={previewData} />
        </div>
        <div className="mt-8 mb-1 text-right">
          <Button
            filledColor="secondary"
            type="submit"
            className="ml-auto"
          >
            {buttonText}
          </Button>
        </div>
      </Form>
    </>
  );
};

TipsForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  isEditForm: PropTypes.bool,
  formData: PropTypes.shape({
    tipDetails: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.string,
      cultureProfile: PropTypes.number,
      type: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
    types: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
    cultures: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
        cultureGuideURL: PropTypes.string,
        flagIcon: PropTypes.string,
      })),
  }).isRequired,
};

TipsForm.defaultProps = {
  isEditForm: false,
};

export default TipsForm;
