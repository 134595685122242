/* eslint-disable new-cap */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../context/API';

import { recordVideoWatchedAction } from '../../actions/videos';
import { trackViewVideo } from '../../lib/tracker/profile';

const Video = ({ wistiaId, trackingId, playlistId, pageTitle, recordWatchHistory }) => {
  const { apiService } = useContext(APIContext);
  const [videoClassName, setVideoClassName] = useState();

  const wistiaConfig = {
    id: wistiaId,
    onReady(video) {
      const { _hashedId, data: { media: { name } } } = video;
      video.bind('play', async () => {
        await trackViewVideo(_hashedId, pageTitle, name);
      });
      if (recordWatchHistory) {
        video.bind('percentwatchedchanged', async (percent, lastPercent) => {
          if (percent >= 0.90 && lastPercent < 0.90) {
            await recordVideoWatchedAction(apiService, _hashedId, trackingId);
          }
        });
      }
    },
  };

  useEffect(() => {
    window._wq = window._wq || [];
    if (!window._wq.some(config => config.id === wistiaId)) {
      window._wq.push(wistiaConfig);
    }
    let className = wistiaId;
    if (playlistId) className = playlistId;
    setVideoClassName(`wistia_embed wistia_async_${className} videoFoam=true`);
  }, [wistiaId]);

  return (
    <div className="wistia_responsive_padding relative p-0 pt-[56.25%]">
      <div className="absolute top-0 left-0 w-full h-full wistia_responsive_wrapper">
        <div className={videoClassName} width="100%" height="100%">&nbsp;</div>
      </div>
    </div>
  );
};

Video.propTypes = {
  wistiaId: PropTypes.string.isRequired,
  trackingId: PropTypes.string,
  playlistId: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  recordWatchHistory: PropTypes.bool,
};

Video.defaultProps = {
  playlistId: null,
  trackingId: null,
  recordWatchHistory: false,
};

export default Video;
