import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';

import withServerSideData from '../../HOC/withServerSideData';

import PageHeader from '../../components/PageHeader';
import CultureCards from '../../components/Dashboard/CultureCards';
import LabelInput from '../../components/common/LabelInput';
import Icon from '../../components/common/Icon';

import { filterItems } from '../../lib/filter-items';
import { prefixHostname } from '../../lib/cdn';
import { trackGuidesHeaderImageClicked, trackViewGuides } from '../../lib/tracker/guides';
import { determineSkillsRedirect } from '../../lib/skills';

const sortCultures = guides => guides
  .map(culture => ({
    id: culture.id,
    name: culture.shortDisplayName,
    value: culture.cultureGuideUrl,
    flagIcon: culture.flagIcon,
  })).sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

const getRandomGuide = guides => {
  const randomIndex = Math.floor(Math.random() * guides.length);
  return guides[randomIndex];
};

const GlobeSmartGuide = ({ initialData: { activity, guides } }) => {
  const [initialCultures, setIntialCultures] = useState(guides);
  const [filteredArray, setFilteredArray] = useState(guides);
  const [filterValue, setFilterValue] = useState('');
  const [currentGuide, setCurrentGuide] = useState();
  const [showHeroImage, setShowHeroImage] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [guideLinkSubdirectory, setGuideLinkSubdirectory] = useState('');

  const handleClearFilter = e => {
    e.preventDefault();
    setFilterValue('');
    setFilteredArray(initialCultures);
  };

  useEffect(() => {
    setIntialCultures(guides);
    setFilteredArray(guides);
  }, [guides, initialCultures]);

  useEffect(() => {
    trackViewGuides();
    if (!currentGuide) {
      setCurrentGuide(getRandomGuide(filteredArray));
    }
    const interval = setInterval(() => {
      if (filteredArray.length && !isHovered) {
        setShowHeroImage(false);
        setTimeout(() => {
          setCurrentGuide(getRandomGuide(filteredArray));
          setShowHeroImage(true);
        }, 900);
      }
    }, 7000);

    return () => {
      clearInterval(interval);
    };
  }, [isHovered]);

  useEffect(() => {
    if (activity) {
      const skillsRedirect = determineSkillsRedirect(activity);
      setGuideLinkSubdirectory(skillsRedirect);
    }
  }, []);

  const handleFilterCultures = e => {
    const { value } = e.target;
    setFilterValue(value);

    if (value) {
      const culturesNamesMatch = filterItems(value, 'shortDisplayName', initialCultures);
      const filtered = [...new Set([...culturesNamesMatch])];
      return setFilteredArray(filtered);
    }
    return setFilteredArray(initialCultures);
  };

  return currentGuide ? (
    <>
      <PageHeader
        pageTitle="GlobeSmart Guides"
        skipTarget="#guides"
        hideHeading
      />
      <section className="!col-span-full">
        <div className="!block full-width">
          <div
            className="overflow-hidden relative h-64 2xl:h-72 bg-cover bg-[50%] bg-no-repeat"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={prefixHostname(currentGuide.heroImage)}
              alt={currentGuide.credit}
              className={`${showHeroImage ? 'opacity-100' : 'opacity-0'} object-cover w-full h-full transform transition-opacity ease-in duration-1000`}
            />
            <div className="absolute inset-0 bg-gray-900 bg-opacity-40">
              <div className="flex flex-col items-center justify-center h-full gap-y-2 md:gap-y-4">
                <div className="flex items-center gap-2 md:gap-4">
                  <Icon icon="culture-guide" className="w-8 h-8 md:w-12 md:h-12" iconColor="white" />
                  <h1 className="mb-0 font-serif text-white lg:text-6xl">GlobeSmart Guides</h1>
                </div>
                <div className="md:w-full w-[22rem] p-3 max-w-lg rounded-md">
                  <LabelInput
                    id="filterValue"
                    name="filterValue"
                    labelType="text"
                    value={filterValue}
                    onChangeValue={handleFilterCultures}
                    type="text"
                    placeholderText="Search Cultures"
                    leadingIcon="search"
                    trailingIcon={filterValue ? 'x-close' : ''}
                    handleIconClick={handleClearFilter}
                  />
                </div>
              </div>
              <div className={`${showHeroImage ? 'opacity-100' : 'opacity-0'} absolute bottom-2 right-2 transform transition-opacity ease-in duration-1000`}>
                <a
                  className="flex items-center p-1 transition-all duration-300 rounded-md hover:bg-white hover:bg-opacity-20"
                  onClick={() => trackGuidesHeaderImageClicked(currentGuide.shortDisplayName)}
                  href={currentGuide.cultureGuideUrl}
                >
                  <img
                    src={prefixHostname(currentGuide.flagIcon)}
                    alt={currentGuide.credit}
                    className="w-8 h-8 mr-3 rounded-md"
                  />
                  <div
                    className="mb-0 text-white text-[1rem] hover:bg-transparent"
                  >{parser(DOMPurify.sanitize(currentGuide.shortDisplayName, { ADD_ATTR: ['target'] }))}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className={`full-width !flex justify-end mr-2 mt-2 ${showHeroImage ? 'opacity-100' : 'opacity-0'} transform transition-opacity ease-in duration-1000`}>
            <span className="text-xs italic font-light">
              {parser(DOMPurify.sanitize(currentGuide.credit, { ADD_ATTR: ['target'] }))}
            </span>
          </div>
        </div>
        <div className="px-4 py-4 breakout">
          <CultureCards
            cultures={sortCultures(filteredArray)}
            applyGrid="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 md:px-0 lg:px-0 overflow-y-auto"
            expandedBox="py-2 px-7 md:px-4 lg:px-8"
            launchedFrom="Guides Home"
            guideLinkSubdirectory={guideLinkSubdirectory}
          />
        </div>
      </section>
    </>
  ) : null;
};

GlobeSmartGuide.getAPIDataKey = () => 'guides';
GlobeSmartGuide.getData = apiService =>
  apiService.get('guides').then(data => ({ guides: data }));

GlobeSmartGuide.propTypes = {
  initialData: PropTypes.shape({
    activity: PropTypes.string,
    guides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      shortDisplayName: PropTypes.string.isRequired,
      cultureGuideUrl: PropTypes.string.isRequired,
      flagIcon: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};

export default withServerSideData(GlobeSmartGuide);
