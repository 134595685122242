/* eslint-disable prefer-arrow-callback */
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import Tag from '../../Tag';
import Icon from '../../Icon';

const DropdownMultiSelect = forwardRef(
  function DropdownMultiSelect({
    name,
    labelText,
    options: initialOptions,
    placeholder,
    trailingIcon,
    onSelectOption,
    onRemoveSelectedOption,
    selectedOptions,
    closeOtherDropdowns,
    helperText,
    errorMessage,
    handleClearIconClick,
  }, ref) {
    const [multiSelectDropdownOpen, setMultiSelectDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const filteredOptions = selectedOptions.length
      ? initialOptions.filter(opt => !selectedOptions.some(so => so.value === opt.value))
      : initialOptions;

    const handleClickOutside = e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setMultiSelectDropdownOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const onSuggestionSelected = option => {
      onSelectOption({
        target: {
          name,
          value: [...selectedOptions, option],
        },
      });
    };

    useImperativeHandle(ref, () => ({
      openDropdown: () => setMultiSelectDropdownOpen(true),
      closeDropdown: () => setMultiSelectDropdownOpen(false),
    }));

    return (
      <div className="relative w-full mt-2" ref={dropdownRef}>
        <p className="mb-2 font-sans text-sm font-medium leading-6 text-left text-gray-700">{labelText}</p>

        {/* Selected Tags */}
        <div
          className={`flex items-center w-full rounded-md border-inherit border-0 px-2 py-1.5 shadow-sm cursor-pointer
          ${multiSelectDropdownOpen ? 'ring-1 ring-rust-500 ' : 'ring-1 ring-gray-200'}
          focus:ring-inset focus:ring-rust-500 focus:shadow-input focus:outline-none sm:text-sm sm:leading-6`}
          onClick={() => {
            setMultiSelectDropdownOpen(true);
            closeOtherDropdowns(ref.current);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setMultiSelectDropdownOpen(true);
              closeOtherDropdowns(ref.current);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <div className="w-11/12">
            {selectedOptions.length > 0 ? (
              <ul className="flex flex-wrap gap-2 list-none">
                {selectedOptions.map(option => (
                  <Tag
                    text={option.name}
                    key={option.value}
                    size="xs"
                    onIconClick={e => { e.stopPropagation(); onRemoveSelectedOption(option); }}
                  />
                ))}
              </ul>
            ) : (
              <span className="text-gray-500 sm:text-sm sm:leading-6">
                {placeholder}
              </span>
            )}
          </div>
          <div className="flex">
            <Icon icon={trailingIcon} iconColor="dark-gray" className="w-2.5 h-2.5 ml-2 " aria-hidden="true" />
            {selectedOptions.length > 0 && (
              <div
                role="button"
                onClick={e => { e.stopPropagation(); handleClearIconClick(); }}
                tabIndex="-1"
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleClearIconClick();
                  }
                }}
              >
                <Icon icon="x-close" iconColor="dark-gray" className="w-2.5 h-2.5 ml-2 " aria-hidden="true" />
              </div>
            )}
          </div>
        </div>

        {/* Dropdown List */}
        {
          multiSelectDropdownOpen && (
            <div className="absolute left-0 right-0 z-10 mt-1 rounded-md border-0 py-1.5 shadow-sm bg-gray-100  ring-1 ring-rust-500 ring-opacity-5 focus:ring-inset focus:ring-rust-500">
              <div className="overflow-y-auto max-h-50">
                {filteredOptions.length > 0 && (
                  filteredOptions.map(option => (
                    <div
                      role="option"
                      name={name}
                      key={option.value}
                      onClick={() => {
                        onSuggestionSelected(option);
                        setMultiSelectDropdownOpen(false);
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          onSuggestionSelected(option);
                          dropdownRef.current.focus();
                        }
                      }}
                      className="px-4 py-2 cursor-pointer"
                      tabIndex="-1"
                      aria-selected={selectedOptions?.some(so => so.value === option.value)}
                    >
                      {option.name}
                    </div>
                  ))
                )}
              </div>
            </div>
          )
        }

        {helperText && !errorMessage ? <p className="mt-2 font-sans text-sm text-left text-gray-500">{helperText}</p> : null}
        {errorMessage ? <p className="h-4 mt-2 font-sans text-sm text-left text-red-500">{errorMessage}</p> : null}
      </div>
    );
  },
);

DropdownMultiSelect.propTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })),
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })),
  onSelectOption: PropTypes.func,
  onRemoveSelectedOption: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  trailingIcon: PropTypes.string,
  closeOtherDropdowns: PropTypes.func,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
  handleClearIconClick: PropTypes.func,
};

DropdownMultiSelect.defaultProps = {
  name: '',
  labelText: '',
  options: [],
  selectedOptions: [],
  onSelectOption: null,
  onRemoveSelectedOption: null,
  helperText: '',
  errorMessage: '',
  trailingIcon: '',
  handleClearIconClick: () => { },
  closeOtherDropdowns: () => { },
};

export default DropdownMultiSelect;
