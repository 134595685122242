import tracker from '../index';

const {
  eventNames,
  eventProps,
  resolveAfterTimeout,
  checkOrgNames,
} = tracker;

const trackViewDashboard = orgs => {
  tracker.track(eventNames.VIEW_DASHBOARD, {
    [eventProps.SUCCESS]: true,
    [eventProps.ORGANIZATION_NAME]: checkOrgNames(orgs),
  });

  return resolveAfterTimeout;
};

const trackViewBannerResource = name => {
  tracker.track(eventNames.VIEW_BANNER_RESOURCE, {
    [eventProps.RESOURCE_NAME]: name,
  });

  return resolveAfterTimeout;
};

const trackDashboardBlocksSearch = searchTerm => {
  tracker.track(eventNames.DASHBOARD_SEARCH, {
    [eventProps.SEARCH_TERM]: searchTerm,
  });

  return resolveAfterTimeout;
};

const trackDashboardTipsClick = (apiService, tipType, cultureName, link, tipText) => {
  apiService.post('trackers/mixpanel', {
    eventName: eventNames.CLICK_DASHBOARD_TIPS,
    properties: {
      [eventProps.TIP_TYPE]: tipType,
      [eventProps.CULTURE_NAME]: cultureName,
      [eventProps.LINK]: link,
      [eventProps.TIP_TEXT]: tipText,
    },
  },
  );

  return resolveAfterTimeout;
};

export {
  trackViewDashboard,
  trackViewBannerResource,
  trackDashboardBlocksSearch,
  trackDashboardTipsClick,
};
