import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { atcb_action as addToCalendar } from 'add-to-calendar-button';

import Table from '../../../common/Table';
import Avatar from '../../../common/Avatars/Avatar';
import Button from '../../../common/Button';

import { trackAddSessionToCalendar } from '../../../../lib/tracker/aperian-live';
import { formatCalendarData } from '../../../../lib/aperianLive';

const CourseCatalogTable = ({
  formattedSessions,
  register,
  userId,
  organizations,
  duration,
  name,
}) => {
  const buttonRefs = useRef({});

  const calculateAddToCalendar = async (
    dateTime,
    userJoinUrl,
    nameInternal,
    cohortCode,
    facilitator,
  ) => {
    const calendarConfig = formatCalendarData(dateTime, duration, userJoinUrl, name);

    await addToCalendar(calendarConfig, buttonRefs.current[cohortCode]);
    await trackAddSessionToCalendar(
      userId,
      organizations,
      name,
      nameInternal,
      cohortCode,
      dateTime,
      duration,
      facilitator,
      'Course Catalog Table',
    );
  };

  return (
    <Table
      className="min-w-full divide-y divide-gray-300"
      emptyMessage="There are no scheduled upcoming sessions at this time."
      columns={[
        {
          title: 'Facilitator',
          cellStyle: 'whitespace-normal lg:whitespace-nowrap pr-2 pl-3 py-4 md:pl-4 md:pr-3 text-sm font-medium text-gray-900',
          field: 'facilitator',
          render: rowData => (
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <Avatar
                  className="w-12 h-auto rounded-full bg-gray-50"
                  avatarImgAlt={rowData.facilitator}
                  avatarUrl={rowData.facilitatorAvatarUrl}
                  isNavigation
                />
                {rowData.facilitator}
              </div>
              <div className="block text-sm md:hidden">
                {rowData.date} {rowData.time}
              </div>
            </div>
          ),
        },
        {
          title: 'Date',
          headerStyle: 'hidden md:table-cell py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
          cellStyle: 'hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500',
          field: 'dateTime',
          render: rowData => rowData.date,
        },
        {
          title: 'Time',
          headerStyle: 'hidden md:table-cell py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
          cellStyle: 'hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500',
          field: 'time',
          render: rowData => rowData.time,
        },
        {
          title: 'Register',
          headerStyle: 'hidden',
          cellStyle: 'whitespace-nowrap pl-2 pr-3 md:px-3 py-4 text-sm text-gray-500',
          render: ({
            isRegistered,
            dateTime,
            cohortCode,
          }) => {
            const todaysDate = format(new Date(), 'yyyy-MM-dd hh:mm');
            const sessionDate = format(new Date(dateTime), 'yyyy-MM-dd hh:mm');
            return (
              <div className="flex justify-center">
                { (todaysDate > sessionDate) && !isRegistered
                  ? <p className="mb-0 text-sm font-semibold tracking-wider px-3.5 py-2 text-charcoal-200">Closed</p>
                  : (
                    <Button
                      onClick={() => register(
                        cohortCode,
                        'course catalog table',
                      )}
                      isExtraSmall
                      className="w-full"
                      variant="primary"
                      disabled={!!isRegistered}
                    >
                      {isRegistered ? 'Registered' : 'Register'}
                    </Button>
                  )}
              </div>
            );
          },
        },
        {
          title: 'Add Calendar',
          headerStyle: 'hidden',
          cellStyle: 'whitespace-nowrap pl-2 pr-3 md:px-3 py-4 text-sm text-gray-500',
          render: ({
            isRegistered,
            dateTime,
            userJoinUrl,
            nameInternal,
            facilitator,
            cohortCode,
          }) => (
            isRegistered
              ? (
                <Button
                  isExtraSmall
                  isCircle
                  ref={el => {
                    buttonRefs.current[cohortCode] = el;
                  }}
                  leadingButtonIcon="calendar-plus"
                  iconColor="primary"
                  variant="secondary"
                  className="w-full p-3 md:w-auto"
                  onClick={() => calculateAddToCalendar(
                    dateTime,
                    userJoinUrl,
                    nameInternal,
                    cohortCode,
                    facilitator,
                  )}
                />
              )
              : null
          ),
        },
      ]}
      data={formattedSessions}
      options={{
        headersStyle: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
        bodyStyle: 'divide-y',
      }}
    />
  );
};

CourseCatalogTable.propTypes = {
  formattedSessions: PropTypes.arrayOf(
    PropTypes.shape({
      cohortCode: PropTypes.string,
      date: PropTypes.string,
      dateTime: PropTypes.string,
      facilitator: PropTypes.string,
      facilitatorAvatarUrl: PropTypes.string,
      isRegistered: PropTypes.bool,
      nameInternal: PropTypes.string,
      time: PropTypes.string,
      userJoinUrl: PropTypes.string,
    }),
  ).isRequired,
  userId: PropTypes.string.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  duration: PropTypes.number,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
};

CourseCatalogTable.defaultProps = {
  organizations: [],
  duration: null,
};

export default CourseCatalogTable;
