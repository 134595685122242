import React from 'react';
import { Link } from 'react-router';
import ElementQuery from 'react-eq';
import PropTypes from 'prop-types';

const TipsData = ({ tips }) => tips.map(({
  id,
  title,
  text,
  link,
  type,
  countryName,
}) => (
  <tr key={id} className="odd:bg-[#f7f7f7] m-auto table-row box-border rounded border-collapse">
    <td className="table-cell py-2 pl-2 pr-4 m-auto text-lg text-left text-nowrap" data-label="Title">
      <Link to={`/administration/tips/${id}/edit`}>{title}</Link>
    </td>
    <td className="table-cell p-2 m-auto text-lg text-left" data-label="Text">
      {text.replace(/<\/?[^>]+(>|$)/g, '')}
    </td>
    <td className="table-cell p-2 m-auto text-lg text-left" data-label="Link">
      {link}
    </td>
    <td className="table-cell p-2 m-auto text-lg text-left" data-label="Type">
      {type}
    </td>
    <td className="table-cell p-2 m-auto text-lg text-left" data-label="Culture">
      {countryName}
    </td>
  </tr>
));

const TipsList = ({ tips }) => {
  if (!tips || !tips.length) return null;

  return (
    <ElementQuery queries={{ 'table-view': 700 }}>
      <table className="table w-full mx-0 my-8 bg-white border-collapse">
        <thead className="static w-auto h-auto overflow-visible">
          <tr>
            <th className="table-cell p-2 m-auto text-lg text-left">
              Title
            </th>
            <th className="table-cell p-2 m-auto text-lg text-left">
              Text
            </th>
            <th className="table-cell p-2 m-auto text-lg text-left">
              Link
            </th>
            <th className="table-cell p-2 m-auto text-lg text-left">
              Type
            </th>
            <th className="table-cell p-2 m-auto text-lg text-left">
              Culture
            </th>
          </tr>
        </thead>
        <tbody className="box-border table-row-group border-collapse">
          <TipsData tips={tips} />
        </tbody>
      </table>
    </ElementQuery>
  );
};

TipsList.propTypes = {
  tips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string,
      link: PropTypes.string,
      type: PropTypes.string,
      countryName: PropTypes.string,
    })).isRequired,
};

export default TipsList;
