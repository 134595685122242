/* eslint-disable new-cap */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../context/API';
import { getTips } from '../../../actions/tips';

import { trackDashboardTipsClick } from '../../../lib/tracker/dashboard';
import TipCard from './TipCard';

const DashboardTips = ({
  tips: initialTips,
  accessLevel,
}) => {
  const [tips, setTips] = useState(initialTips);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showTip, setShowTip] = useState(true);
  const [intervalId, setIntervalId] = useState(null);

  const { apiService } = useContext(APIContext);

  const setNewTips = async () => {
    if (currentIndex === tips.length - 1) {
      try {
        const response = await getTips(apiService);
        setTips(response.tips);
        setCurrentIndex(0);
        setShowTip(true);
      } catch (err) {
        setTips(initialTips);
        setCurrentIndex(0);
        setShowTip(true);
        if (intervalId) {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      }
    } else {
      setCurrentIndex(prev => prev + 1);
      setShowTip(true);
    }
  };

  const handleTipIndexChange = () => {
    setShowTip(false);
    setTimeout(setNewTips, 500);
  };

  useEffect(() => {
    const id = setInterval(handleTipIndexChange, 5000);
    setIntervalId(id);
    return () => clearInterval(id);
  }, [currentIndex]);

  const hasPremiumAccess = accessLevel.some(level => level.startsWith('premium'));

  const trackClickLink = tip => {
    trackDashboardTipsClick(
      apiService,
      tip.tipType,
      tip.title.split('In')[1],
      tip.tipLink.split('/')[1],
      tip.text.replace(/<\/?[^>]+(>|$)/g, ''),
    );
  };

  return (
    <div className="flex flex-col items-center justify-end col-start-1">
      <TipCard
        hasPremiumAccess={hasPremiumAccess}
        showTip={showTip}
        trackClickLink={trackClickLink}
        tip={tips[currentIndex]}
      />
    </div>
  );
};

DashboardTips.propTypes = {
  tips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      text: PropTypes.string,
      tipLink: PropTypes.string,
      flagIcon: PropTypes.string,
      guidesLink: PropTypes.string,
    }),
  ).isRequired,
  accessLevel: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DashboardTips;
