/* eslint-disable react/prop-types */
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import ElementQuery from 'react-eq';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../../context/CurrentUser';
import { APIContext } from '../../../context/API';
import { ToastContext } from '../../../context/Toast';

import withServerSideData from '../../../HOC/withServerSideData';

import PageHeader from '../../PageHeader';
import AssessmentResults from './AssessmentResults';
import GroupsYouAreIn from './GroupsYouAreIn';
import GroupsYouOwn from './GroupsYouOwn';
import TeamsYouAreIn from './TeamsYouAreIn';
import Notification from '../../Notifications/Notification';
import Icon from '../../common/Icon';

import getAssessmentDashboardAction from '../../../actions/assessments/dashboard';
import emailGroupReportAction from '../../../actions/assessments/emailGroupReport';
import InvitationAcceptAction from '../../../actions/invitationAccept';
import InvitationRejectAction from '../../../actions/invitationReject';

import {
  trackEmailGroupReport,
  trackEmailGroupReportFailure,
} from '../../../lib/tracker/assessments/email-group-report';
import { trackJoinAssessmentGroup } from '../../../lib/tracker/assessments/group';
import { trackViewAssessmentHome, trackViewDimensionLearningModuleSuccess } from '../../../lib/tracker/assessments';
import groupRemoveMemberAction from '../../../actions/assessments/groups/groupRemoveMember';
import Button from '../../common/Button';

async function trackLearningDimensionClickEvent(asmtType) {
  const moduleName = 'Understanding the IBI Dimensions';
  await trackViewDimensionLearningModuleSuccess(asmtType, moduleName);
}

const queries = { 'card-layout': 400 };

const Assessment = ({ params: { assessment }, initialData }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { apiService } = useContext(APIContext);
  const { addToast, removeToast } = useContext(ToastContext);

  const [asmtData, setAsmtData] = useState(initialData);
  const [notification, setNotification] = useState(null);
  const notificationRef = useRef(null);

  const isGta = assessment === 'gta';
  const groupOrTeam = isGta ? 'Team' : 'Group';

  useEffect(() => {
    const trackPageView = async () => {
      await trackViewAssessmentHome(asmtData.displayName, assessment);
    };
    trackPageView();
    const isFinished = asmtData.userSurvey.filter(survey => survey.state === 'finished').length > 0;
    let toastId;
    if ((asmtData.asmtType === 'ibi' && isFinished)) {
      toastId = uuidv4();
      addToast({
        id: toastId,
        content: (
          <div>
            <div className="flex items-center mb-2">
              <Icon icon="ibi-symbol" iconColor="gray" className="w-10 h-10" />
              <div className="ml-4">
                <h5 className="mb-0 font-serif text-base text-charcoal-900 md:text-xl">Learn About the IBI Dimensions</h5>
                <p className="m-0 text-sm text-left md:text-base">
                  Gain a deeper understanding of your results
                </p>
              </div>
            </div>
            <Button
              variant="primary"
              className="ml-auto w-fit rounded-full px-4 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              isNormalCase
              isSmall
              to={`${process.env.AWS_APG_RESOURCES_HOST}mod/ibi/`}
              target="_blank"
              onClick={() => trackLearningDimensionClickEvent(asmtData.asmtType)}
              rel="noreferrer"
            >
              View Guide
            </Button>
          </div>),
      });
    }
    return () => {
      if (toastId) removeToast(toastId);
    };
  }, []);

  useEffect(() => {
    if (notification) {
      notificationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [notification]);

  async function refreshData() {
    const asmtDashboard = await getAssessmentDashboardAction(apiService, assessment);
    setAsmtData(asmtDashboard);
  }

  async function onAccept(id) {
    const {
      group: {
        name: groupName,
        assessment: {
          displayName,
          type,
        },
      },
    } = await new InvitationAcceptAction(apiService).execute(id);
    const payload = {
      userId: currentUser.userid,
      groupName,
      asmtName: displayName,
      asmtType: type,
      acceptMethod: 'Interface',
    };
    await trackJoinAssessmentGroup(payload);
    await refreshData();
  }

  async function onReject(id) {
    await new InvitationRejectAction(apiService).execute(id);
  }

  async function emailAssessmentGroupReport(groupId, groupName) {
    try {
      const response = await emailGroupReportAction(apiService, assessment, groupId);
      if (response.status === 200) {
        const { displayName, asmtType } = asmtData;
        await trackEmailGroupReport(
          displayName,
          asmtType,
          groupName,
        );
        setNotification({ type: 'success', message: 'Your report has been emailed to you.' });
      }
    } catch (err) {
      if (err.status === 404 && err.message !== '') {
        setNotification({ type: 'upgrade', message: err.message });
      } else {
        await trackEmailGroupReportFailure(err);
        setNotification({
          type: 'failure',
          message: err.message
            ? err.message
            : 'There was an error sending the group report. Please try again later',
        });
      }
    }
  }

  async function onGroupLeave(groupId) {
    try {
      await groupRemoveMemberAction(apiService, groupId, currentUser.userid);
      await refreshData();
      setNotification({ type: 'success', message: 'Successfully removed from the group.' });
    } catch (err) {
      setNotification({
        type: 'failure',
        message: err.message
          ? err.message
          : 'There was an error while leaving the group. Please try again later.',
      });
    }
  }

  const hasOrg = currentUser.organizations.length > 0;

  return (
    <>
      <PageHeader
        pageTitle={asmtData.displayName}
        icon="ibi-symbol"
        skipTarget={`#your-${asmtData.asmtType}-results`}
      />
      <ElementQuery queries={queries}>
        <div className="box-border">
          {notification
            && (
              <Notification
                ref={notificationRef}
                type={notification.type}
                message={notification.message}
              />
            )}
          {isGta ? (
            <TeamsYouAreIn
              firstName={currentUser.firstName}
              lastName={currentUser.lastName}
              asmtType={asmtData.asmtType}
              groupsYouAreIn={asmtData.groupsYouAreIn}
              groupInvitations={asmtData.pendingInvitations}
              onAccept={onAccept}
              onReject={onReject}
            />
          ) : (
            <>
              <AssessmentResults
                firstName={currentUser.firstName}
                lastName={currentUser.lastName}
                asmtType={asmtData.asmtType}
                userSurvey={asmtData.userSurvey}
                avatarUrl={currentUser.avatarUrl}
              />
              <GroupsYouAreIn
                asmtType={asmtData.asmtType}
                groupsYouAreIn={asmtData.groupsYouAreIn}
                groupInvitations={asmtData.pendingInvitations}
                onAccept={onAccept}
                onReject={onReject}
                onLeave={onGroupLeave}
              />
            </>
          )}
          <GroupsYouOwn
            hasOrg={hasOrg}
            asmtType={asmtData.asmtType}
            groupsOwned={asmtData.groupsOwned}
            groupOrTeam={groupOrTeam}
            emailAssessmentGroupReport={emailAssessmentGroupReport}
            seatsRemaining={asmtData.seatsRemaining}
            refreshData={refreshData}
            isIBIAdmin={currentUser.accessLevel.includes('ibi-admin')}
          />
        </div>
      </ElementQuery>
    </>
  );
};

Assessment.getAPIDataKey = () => 'asmtData';
Assessment.getData = (apiService, { assessment }) =>
  apiService.get(`assessments/${assessment}`).then(data => ({ asmtData: data }));

Assessment.propTypes = {
  params: PropTypes.shape({
    assessment: PropTypes.string.isRequired,
  }).isRequired,
  initialData: PropTypes.shape({
    asmtId: PropTypes.number,
    asmtType: PropTypes.string,
    displayName: PropTypes.string,
    groupsOwned: PropTypes.arrayOf(PropTypes.shape({
      avatarUrl: PropTypes.string,
      createdAt: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.string,
    })),
    groupsYouAreIn: PropTypes.arrayOf(PropTypes.shape({
      avatarUrl: PropTypes.string,
      createdAt: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      joinedAt: PropTypes.string,
    })),
    pendingInvitations: PropTypes.arrayOf(PropTypes.shape({

    })),
    userSurvey: PropTypes.arrayOf(PropTypes.shape({
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      id: PropTypes.number,
      userRole: PropTypes.string,
      state: PropTypes.string,
      editSurveyUrl: PropTypes.string,
      version: PropTypes.number,
      completionDate: PropTypes.string,
      languagePreference: PropTypes.string,
      user: PropTypes.string,
      assessment: PropTypes.number,
    })),
  }).isRequired,
};

export default withServerSideData(Assessment);
