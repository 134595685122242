import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import Icon from '../Icon';

const LabelInput = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function LabelInput(props, ref) {
    const type = props.labelType;

    if (type === 'checkbox') {
      return (
        <label htmlFor={props.id} className="checkbox">
          <input
            id={props.id}
            className="checkbox__input"
            type={type}
            value="Checkbox"
            ref={ref}
          />
          <span className="checkbox__text">{props.labelText}</span>
        </label>
      );
    }

    return (
      <div className="flex-grow">
        <label
          htmlFor={`${props.id}`}
          className={`font-sans block text-sm text-left font-medium leading-6 text-gray-700 ${props.labelText ? 'visible mb-2' : 'invisible'}`}
        >
          {props.labelText}
        </label>
        <div className="relative rounded-lg shadow-sm">
          {props.leadingIcon
            ? (
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <Icon
                  icon={props.leadingIcon}
                  className="w-3 h-4"
                  iconColor="dark-gray"
                />
              </div>
            ) : null}
          <input
            id={props.id}
            name={props.name}
            className={`font-sans font-normal box-border block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-50 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-rust-500  focus:shadow-input sm:text-sm sm:leading-6 ${props.leadingIcon ? 'pl-10' : 'pl-3'}`}
            type={type}
            placeholder={props.placeholderText}
            value={DOMPurify.sanitize(props.value) || ''}
            onChange={props.onChangeValue}
            disabled={props.isDisabled}
            required={props.isRequired}
            onBlur={props.onBlur}
            ref={ref}
            maxLength={props.maxLength}
            min={type === 'number' ? 0 : ''}
            autoComplete={type === 'password' ? 'off' : null}
          />
          {(props.trailingIcon && !props.errorMessage)
            ? (
              <div
                role="button"
                className={`absolute inset-y-0 flex items-center right-2.5 my-1.5 px-0.5 ${props.iconButtonDisabled ? 'no-pointer-events' : 'focus-visible:outline-none focus-visible:ring-rust-600 focus-visible:ring-2 focus-visible:rounded'}`}
                onClick={props.handleIconClick}
                onKeyDown={e => {
                  if (e.keyCode === 32 && !props.iconButtonDisabled) {
                    props.handleIconClick(e);
                  }
                }}
                tabIndex={props.iconButtonDisabled ? -1 : 0}
                aria-disabled={props.iconButtonDisabled}
              >
                <Icon
                  icon={props.trailingIcon}
                  className="w-3 h-3"
                  iconColor={props.iconButtonDisabled ? 'gray' : 'dark-gray'}
                />
              </div>
            ) : null}
          {props.errorMessage
            ? (
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#F04338" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            ) : null}
        </div>
        {props.errorMessage ? (
          <p className="h-4 mt-2 font-sans text-sm text-left text-red-500">
            {props.errorMessage}
          </p>
        ) : null}
        {props.helperText && !props.errorMessage ? (
          <p className="mt-2 font-sans text-sm text-left text-gray-500">
            {props.helperText}
          </p>
        ) : null}
      </div>
    );
  });

LabelInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  leadingIcon: PropTypes.string,
  trailingIcon: PropTypes.string,
  labelText: PropTypes.string,
  labelType: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onChangeValue: PropTypes.func,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  helperText: PropTypes.string,
  handleIconClick: PropTypes.func,
  iconButtonDisabled: PropTypes.bool,
  placeholderText: PropTypes.string,
};

LabelInput.defaultProps = {
  leadingIcon: '',
  trailingIcon: '',
  labelText: '',
  name: '',
  value: '',
  onChangeValue: () => { },
  onBlur: () => { },
  handleIconClick: () => { },
  errorMessage: '',
  labelType: 'text',
  isDisabled: false,
  isRequired: false,
  maxLength: null,
  helperText: '',
  iconButtonDisabled: false,
  placeholderText: '',
};

export default LabelInput;
