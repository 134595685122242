import React, { useContext, useEffect, useState } from 'react';

import { NotificationContext } from '../../../../context/Notification';
import { APIContext } from '../../../../context/API';

import Notification from '../../../Notifications/Notification';
import LabelInput from '../../../common/LabelInput';

import TipsList from './TipsList';

import { searchTipsAction } from '../../../../actions/tips';

const TipsSearch = () => {
  const { apiService } = useContext(APIContext);

  const {
    addNotification,
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [tips, setTips] = useState([]);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  useEffect(() => {
    let timer;
    if (!searchTerm) return setTips([]);
    if (timer) window.clearTimeout(timer);
    timer = setTimeout(() => searchTipsAction(apiService, searchTerm)
      .then(data => setTips(data.tips))
      .catch(err => {
        addNotification({
          type: 'warning',
          message: `Error searching for tip: ${err.message}`,
        });
      }), 250);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  const onSubmit = e => e.preventDefault();

  const onSearchParamChange = ({ target: { value } }) => {
    setSearchTerm(value);
  };

  return (
    <>
      {notificationMessage && <Notification {...notificationMessage} />}
      <div className="pt-1">
        <form onSubmit={onSubmit}>
          <LabelInput
            id="searchTerm"
            name="Search Term"
            labelText="Search Term"
            labelType="text"
            value={searchTerm}
            onChangeValue={onSearchParamChange}
            helperText="Search for a Tip by Title, Text, Link, Type, or Country."
          />
        </form>
      </div>
      {searchTerm !== '' && (tips.length > 0 ? (<TipsList tips={tips} />) : (
        <p>No existing tips match your search criteria. Please try again.</p>
      ))}
    </>
  );
};

export default TipsSearch;
