import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../common/Button';

const TipConfirmModal = ({ onHandleRedirect, onHandleRemain }) => (
  <>
    <h2 className="text-2xl text-center">Tip Created</h2>
    <p className="mb-8 text-center">What would you like to do next?</p>
    <div className="flex items-center justify-end gap-2 mt-8">
      <Button
        onClick={onHandleRedirect}
      >View This Tip
      </Button>
      <Button
        onClick={onHandleRemain}
        outlinedColor="green"
      >Create Another Tip
      </Button>
    </div>
  </>
);

TipConfirmModal.propTypes = {
  onHandleRedirect: PropTypes.func.isRequired,
  onHandleRemain: PropTypes.func.isRequired,
};

export default TipConfirmModal;
