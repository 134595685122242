import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../common/Button';

const TipDeleteModal = ({ onCancel, onConfirm }) => (
  <>
    <h2 className="text-2xl text-center">Are you sure want to delete this tip?</h2>
    <div className="flex items-center justify-end gap-2 mt-8">
      <Button onClick={onCancel}>No, Cancel</Button>
      <Button onClick={onConfirm} outlinedColor="red">Yes, Delete This Tip</Button>
    </div>
  </>
);

TipDeleteModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default TipDeleteModal;
