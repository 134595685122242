export const TAKE_THE_GLOBESMART_PROFILE = 'take_the_globesmart_profile';
export const CREATE_A_GSP_TEAM = 'create_a_gsp_team';
export const VIEW_PROFILE_COMPARISON = 'view_profile_comparison';
export const VIEW_COMMUNICATION_STYLES_FOR_ANY_CULTURE = 'view_communication_styles_for_any_culture';
export const VIEW_CORE_VALUES_FOR_ANY_CULTURE = 'view_core_values_for_any_culture';
export const WATCH_INDEPENDENT_INTERDEPENDENT_VIDEO = 'watch_independent_interdependent_video';
export const WATCH_EGALITARIANISM_STATUS_VIDEO = 'watch_egalitarianism_status_video';
export const WATCH_RISK_CERTAINTY_VIDEO = 'watch_risk_certainty_video';
export const WATCH_DIRECT_INDIRECT_VIDEO = 'watch_direct_indirect_video';
export const WATCH_TASK_RELATIONSHIP_VIDEO = 'watch_task_relationship_video';
export const VIEW_GLOBESMART_PROFILE_COMPARISON_ADVICE = 'view_globesmart_profile_comparison_advice';
export const COMPLETE_GS_DIMENSIONS_AND_INCLUSIVE_ACTIONS_LEARNING_MODULE = 'complete_gs_dimensions_and_inclusive_actions_learning_module';
export const VIEW_GSP_TEAM_AVERAGE = 'view_gsp_team_average';
export const VIEW_ADVICE_FOR_GSP_TEAM_AVERAGE = 'view_advice_for_gsp_team_average';
export const COMPLETE_GIVING_FEEDBACK_ACROSS_CULTURES_LEARNING_MODULE = 'complete_giving_feedback_across_cultures_learning_module';
export const VIEW_THE_GSP_TEAM_PROFILE_ACTIVITY = 'view_the_gsp_team_profile_activity';
export const VIEW_BUILDING_TEAMWORK_FOR_ANY_CULTURE = 'view_building_teamwork_for_any_culture';
export const VIEW_ESTABLISHING_CREDIBILITY_FOR_ANY_CULTURE = 'view_establishing_credibility_for_any_culture';
export const ATTEND_UNDERSTAND_YOUR_WORK_STYLE_WITH_THE_GLOBESMART_PROFILE = 'attend_understand_your_work_style_with_the_globesmart_profile';
