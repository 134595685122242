/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../context/CurrentUser';

import withServerSideData from '../../HOC/withServerSideData';

import PageHeader from '../../components/PageHeader';
import PortraitBlock from '../../components/Block/PortraitBlock';
import LabelInput from '../../components/common/LabelInput';
import Button from '../../components/common/Button';

import { trackLearningModuleHomeViewed, trackLMBlocksSearch } from '../../lib/tracker/learning-module-home';
import DropdownSelect from '../../components/common/Dropdowns/DropdownSelect';
import { incrementsObject } from '../../lib/increments';
import { languageOptions } from '../../lib/language';
import DropdownMultiSelect from '../../components/common/Dropdowns/DropdownMultiSelect';

const LearningModuleHome = ({ initialData: { learningModuleBlocks } }) => {
  const initialBlocks = learningModuleBlocks;
  const { currentUser } = useContext(CurrentUserContext);
  const { connection } = currentUser;
  const languageDropdownRef = useRef(null);
  const tagDropdownRef = useRef(null);

  const closeOtherDropdowns = currentDropdownRef => {
    if (languageDropdownRef.current && languageDropdownRef.current !== currentDropdownRef) {
      languageDropdownRef.current.closeDropdown();
    }
    if (tagDropdownRef.current && tagDropdownRef.current !== currentDropdownRef) {
      tagDropdownRef.current.closeDropdown();
    }
  };

  const [blocks, setBlocks] = useState(learningModuleBlocks);
  const [filterData, setFilterData] = useState({
    minimumTime: '',
    maximumTime: '',
    languages: [],
    tags: [],
    filterValue: '',
  });
  const [error, setError] = useState(null);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);

  useEffect(() => {
    trackLearningModuleHomeViewed();
  }, []);

  useEffect(() => {
    if (!blocks?.length) return;

    const uniqueLang = Array.from(new Set(
      blocks.flatMap(block => block?.languages || []),
    ));

    const filteredLanguages = languageOptions('lm-home').filter(option =>
      uniqueLang.includes(option.value),
    );

    setAvailableLanguages(filteredLanguages);

    const uniqueTag = Array.from(new Set(
      blocks.flatMap(block => block?.tags || []),
    ));

    const filteredTags = uniqueTag.map((tag, i) => ({ name: tag, value: i }));
    setAvailableTags(filteredTags);
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setFilterData({ ...filterData, [name]: value });

    if (name === 'minimumTime' && filterData.maximumTime !== '') {
      if (value && (+filterData.maximumTime < +value)) {
        setError('The maximum time needs to be longer than the selected start time.');
      } else {
        setError(null);
      }
    }

    if (name === 'maximumTime') {
      if (value && (+filterData.minimumTime > +value)) {
        setError('The maximum time needs to be longer than the selected start time.');
      } else {
        setError(null);
      }
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let filteredBlocks = learningModuleBlocks;

      if (filterData.filterValue !== '') {
        const specificKeys = ['title', 'typeName', 'tags', 'description', 'cultures', 'languages'];
        const searchTerm = filterData.filterValue.toLowerCase();
        filteredBlocks = filteredBlocks.filter(block =>
          specificKeys.some(key =>
            block[key]?.toString().toLowerCase().includes(searchTerm)
            || JSON.stringify(block[key])?.toLowerCase().includes(searchTerm),
          ));
      }
      if (filterData.minimumTime !== '' || filterData.maximumTime !== '') {
        filteredBlocks = filteredBlocks.filter(block => {
          const moduleEndTime = +block.moduleRangeEnd || +block.externalRangeEnd;
          let timeFilter;
          if (filterData.minimumTime !== '' && filterData.maximumTime === '') {
            timeFilter = moduleEndTime >= filterData.minimumTime;
          } else if (filterData.minimumTime === '' && filterData.maximumTime !== '') {
            timeFilter = moduleEndTime <= filterData.maximumTime;
          } else {
            timeFilter = moduleEndTime >= filterData.minimumTime
            && moduleEndTime <= filterData.maximumTime;
          }
          return timeFilter;
        });
      }
      if (filterData.languages.length > 0) {
        const selectedLanguageValues = filterData.languages.map(lang => lang.value);
        filteredBlocks = filteredBlocks.filter(block =>
          block.languages?.some(lang => selectedLanguageValues.includes(lang),
          ));
      }
      if (filterData.tags.length > 0) {
        const selectedTags = filterData.tags.map(tag => tag.name);
        filteredBlocks = filteredBlocks.filter(block =>
          block.tags?.some(tag => selectedTags.includes(tag),
          ));
      }
      setBlocks(filteredBlocks);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [filterData, learningModuleBlocks]);

  const handleClearFilter = name => {
    setFilterData({ ...filterData, [name]: (name === 'languages' || name === 'tags') ? [] : '' });
  };

  const handleInputOnBlur = () => {
    if (filterData.filterValue.length > 2) {
      trackLMBlocksSearch(filterData.filterValue);
    }
  };

  const handleRemoveLanguage = data => {
    setFilterData({
      ...filterData,
      languages: filterData.languages
        .filter(language => language.value !== data.value),
    });
  };
  const handleRemoveTag = data => {
    setFilterData({
      ...filterData,
      tags: filterData.tags
        .filter(tag => tag.name !== data.name),
    });
  };

  return (
    <>
      <PageHeader
        pageTitle="Learning Modules"
        icon="learning"
        skipTarget="#learning-module-home"
        isLearningModule
      />
      <div>
        <div className="p-2">
          <LabelInput
            id="filterValue"
            name="filterValue"
            labelType="text"
            value={filterData.filterValue}
            onChangeValue={handleChange}
            onBlur={handleInputOnBlur}
            type="text"
            placeholderText="Start typing to search learning modules…"
            leadingIcon="search"
            trailingIcon="x-close"
            handleIconClick={() => handleClearFilter('filterValue')}
            iconButtonDisabled={!filterData.filterValue}
          />
          <div className="grid mt-1 space-x-2 md:grid-cols-6">
            <div>
              <DropdownSelect
                id="minimumTime"
                name="minimumTime"
                labelType="text"
                options={[{ name: 'Minimum Time', value: '' }, ...incrementsObject(0, 120, 5)]}
                value={filterData.minimumTime}
                onChangeValue={handleChange}
                isLearningModule
                trailingIcon="x-close"
                handleIconClick={() => handleClearFilter('minimumTime')}
                iconButtonDisabled={!filterData.minimumTime}
              />
            </div>
            <div>
              <DropdownSelect
                id="maximumTime"
                name="maximumTime"
                labelType="text"
                options={[{ name: 'Maximum Time', value: '' }, ...incrementsObject(0, 120, 5)]}
                value={filterData.maximumTime}
                onChangeValue={handleChange}
                errorMessage={error}
                isLearningModule
                trailingIcon="x-close"
                handleIconClick={() => handleClearFilter('maximumTime')}
                iconButtonDisabled={!filterData.maximumTime}
              />
            </div>
            <div className="md:col-span-2">
              {availableLanguages?.length > 0 && (
                <DropdownMultiSelect
                  ref={languageDropdownRef}
                  name="languages"
                  options={availableLanguages}
                  placeholder="Select Languages"
                  trailingIcon="chevron-down"
                  onSelectOption={handleChange}
                  onRemoveSelectedOption={handleRemoveLanguage}
                  selectedOptions={filterData.languages}
                  handleClearIconClick={() => handleClearFilter('languages')}
                  closeOtherDropdowns={closeOtherDropdowns}
                />
              )}
            </div>
            <div className="md:col-span-2">
              {availableTags?.length > 0 && (
                <DropdownMultiSelect
                  ref={tagDropdownRef}
                  name="tags"
                  options={availableTags}
                  placeholder="Select Tags"
                  trailingIcon="chevron-down"
                  onSelectOption={handleChange}
                  onRemoveSelectedOption={handleRemoveTag}
                  selectedOptions={filterData.tags}
                  handleClearIconClick={() => handleClearFilter('tags')}
                  closeOtherDropdowns={closeOtherDropdowns}
                />
              )}
            </div>
          </div>
        </div>
        <section className="flex flex-row w-full px-6 py-8 mx-auto mt-1 text-center xl:mb-10 xl:flex-col md:flex-row bg-ivory-100 md:px-8 md:py-12 xl:px-12 rounded-3xl">
          {initialBlocks.length === 0 ? (
            <div className="flex justify-center">
              <div className="flex flex-col justify-between w-full px-6 md:pl-10 max-w-[610px]">
                <h3 className="mb-4 font-serif leading-snug tracking-wider text-left text-charcoal-900">
                  <span className="underline decoration-rust-500 decoration-2 underline-offset-8 md:underline-offset-[15px]">
                    You d
                  </span>
                  on&apos;t have any learning modules
                </h3>
                <p className="py-2 font-sans text-base font-light text-left md:text-sm">
                  It looks like you don&apos;t have any learning modules.
                  You can <a target="_blank" href="https://aperian.com/contact/" rel="noreferrer">reach out</a> to learn more about getting access!
                  If you think this is an error, please contact <a target="_blank" href="https://aperian.zendesk.com/hc/" rel="noreferrer">support</a> for additional assistance.
                </p>
                <div className="flex">
                  <Button
                    to="/dashboard"
                    filledColor="primary"
                    className="ml-auto whitespace-nowrap"
                    isExtraSmall
                  >Return to Dashboard
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            (Object.keys(blocks).length === 0 && initialBlocks.length !== 0) ? (
              <div className="flex justify-center">
                <div className="flex flex-col justify-between w-full px-6 md:pl-10 max-w-[610px]">
                  <h3 className="mb-4 font-serif leading-snug tracking-wider text-left text-charcoal-900">
                    <span className="underline decoration-rust-500 decoration-2 underline-offset-8 md:underline-offset-[15px]">
                      You
                    </span>
                    r search didn&apos;t match any results
                  </h3>
                  <p className="py-2 font-sans text-base font-light text-left md:text-sm">
                    It looks like the item you&apos;re looking for isn&apos;t here.
                    Please alter your search or reach out to support for
                    additional assistance.
                  </p>
                  <div className="flex">
                    <Button
                      filledColor="primary"
                      className="ml-auto whitespace-nowrap"
                      isExtraSmall
                      onClick={handleClearFilter}
                    >Return to Learning Modules
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                {blocks
                  .sort((a, b) => (a.title > b.title ? 1 : -1))
                  .map(block => (
                    <PortraitBlock
                      key={`${block.id}-${block.internalName}`}
                      {...block}
                      connection={connection}
                      languages={block.languages}
                      directUrl={block.externalDirectUrl}
                      completionRangeStart={block.moduleRangeStart || block.externalRangeStart}
                      completionRangeEnd={block.moduleRangeEnd || block.externalRangeEnd}
                    />
                  ))}
              </div>
            )
          )}
        </section>
      </div>
    </>
  );
};

LearningModuleHome.getAPIDataKey = () => 'learningModuleHome';
LearningModuleHome.getData = apiService => apiService.get('blocks/getLearningModules')
  .then(learningModuleHome => ({ learningModuleHome }));

LearningModuleHome.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    learningModuleBlocks: PropTypes.arrayOf(
      PropTypes.shape({
        connection: PropTypes.string,
        blockType: PropTypes.number,
        typeName: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        completionRangeStart: PropTypes.string,
        completionRangeEnd: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        blockImage: PropTypes.string,
        defaultImageLink: PropTypes.string,
        buttonText: PropTypes.string,
        html: PropTypes.string,
        moduleId: PropTypes.number,
        directUrl: PropTypes.string,
        asmtId: PropTypes.string,
        internalName: PropTypes.string,
        languages: PropTypes.arrayOf(PropTypes.string),
      })).isRequired,
  }).isRequired,
};

export default withServerSideData(LearningModuleHome);
