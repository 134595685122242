import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '../../../common/Button';
import LabelInput from '../../../common/LabelInput';
import Tooltip from '../../../common/Tooltip';
import PasswordInputIcon from '../icons/PasswordInputIcon';

// RegExps to test the password against.
const requiredCriteria = {
  // At least 10 characters
  chars10: /^.{10,}$/,
  // No more than 2 identical chars in a row
};

// Group of RegExps that only need a certain number to pass.
const regExpGroup = {
  criteria: {
    // At least 1 lowercase letters (A-Z)
    lowercase1: /[a-z]/,
    // At least 1 uppercase letters (A-Z)
    uppercase1: /[A-Z]/,
    // At least 1 numbers (0-9)
    numbers1: /\d/,
    // At least 1 special characters (!@#$%^&*)
    specialChar1: /[^a-zA-Z0-9]{1,}/,
  },
  numberToPass: 3,
};

const testRequiredCriteria = (criteria, password) => (
  criteria.every(cr => new RegExp(cr).test(password))
);

const testGroupCriteria = (criteria, password, numberToPass) => {
  let passedCriteria = 0;

  criteria.forEach(cr => {
    const passesTest = new RegExp(cr).test(password);
    if (passesTest) passedCriteria += 1;
  });

  return passedCriteria >= numberToPass;
};

const getTooltipListCriteriaClasses = pass => classnames({
  'text-lime-500': pass,
  'transition duration-500': true,
});

const setPassClassName = (criteria, password, numberToPass = null) => {
  let pass = false;

  if (numberToPass) {
    pass = testGroupCriteria(criteria, password, numberToPass);
  }

  if (criteria && !numberToPass) {
    pass = criteria.every(cr => new RegExp(cr).test(password));
  }

  return getTooltipListCriteriaClasses(pass);
};

const InvalidMessage = ({ password, passesRegExGroup }) => (
  <ul className="mx-5 my-4 text-sm font-light leading-loose text-left list-disc list-inside w-max text-rust-500">
    <li className={setPassClassName([requiredCriteria.chars10], password)}>
      Passwords must be at least 10 characters in length
    </li>
    <li className={getTooltipListCriteriaClasses(passesRegExGroup)}>
      Must contain 3 of the following 4 character types
    </li>
    <li className="list-none indent-6">
      <ul className="text-white list-disc list-inside">
        <li className={setPassClassName([regExpGroup.criteria.lowercase1], password)}>
          Lower case letters (a-z)
        </li>
        <li className={setPassClassName([regExpGroup.criteria.uppercase1], password)}>
          Upper case letters (A-Z)
        </li>
        <li className={setPassClassName([regExpGroup.criteria.numbers1], password)}>
          Numbers (0-9)
        </li>
        <li className={setPassClassName([regExpGroup.criteria.specialChar1], password)}>
          Special characters (!@#$%^&*)
        </li>
      </ul>
    </li>
  </ul>
);

InvalidMessage.propTypes = {
  passesRegExGroup: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
};

const PasswordInput = ({ onSubmit, handleUpdateForm, isSingleStepForm, passwordError }) => {
  const [password, setPassword] = useState(null);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [passesRequiredCriteria, setPassesRequiredCriteria] = useState(false);
  const [passesRegExGroup, setPassesRegExGroup] = useState(false);

  useEffect(() => {
    if (!password) {
      setIsValid(false);
      return;
    }

    setPassesRequiredCriteria(testRequiredCriteria(
      Object.values(requiredCriteria),
      password,
    ));

    setPassesRegExGroup(testGroupCriteria(
      Object.values(regExpGroup.criteria),
      password,
      regExpGroup.numberToPass,
    ));
  }, [password]);

  useEffect(() => {
    setIsValid(passesRequiredCriteria && passesRegExGroup);
  }, [passesRequiredCriteria, passesRegExGroup]);

  useEffect(() => {
    if (isValid) {
      handleUpdateForm({ password });
    } else if (isSingleStepForm) {
      handleUpdateForm({ password: '' });
    }
  }, [isValid]);

  const handlePasswordDisplay = () => setDisplayPassword(!displayPassword);

  const onChangeValue = ({ target: { value } }) => setPassword(value);

  if (isSingleStepForm) {
    return (
      <Tooltip
        className="w-full"
        content={(!isValid && password)
          && <InvalidMessage password={password} passesRegExGroup={passesRegExGroup} />}
      >
        <LabelInput
          id="password"
          name="password"
          labelText="Password"
          placeholderText="Password"
          labelType={displayPassword ? 'text' : 'password'}
          value={password}
          onChangeValue={onChangeValue}
          handleIconClick={handlePasswordDisplay}
          leadingIcon="passcode"
          trailingIcon={`eye${displayPassword ? '-off' : ''}`}
          errorMessage={passwordError}
        />
      </Tooltip>
    );
  }

  return (
    <div className="flex flex-col items-center mb-0 xs:mb-24 md:!mb-0">
      <PasswordInputIcon />
      <h1 className="max-w-2xl mb-5 font-serif text-4xl tracking-wider text-charcoal-900 md:text-6xl md:mb-10">Add a password to finish setting up <em className="text-rust-500">your account.</em></h1>
      <Tooltip
        className="w-full"
        content={(!isValid && password)
          && <InvalidMessage password={password} passesRegExGroup={passesRegExGroup} />}
      >
        <div className="w-5/6 max-w-md mx-auto md:w-full">
          <LabelInput
            id="password"
            name="password"
            placeholderText="Password"
            labelType={displayPassword ? 'text' : 'password'}
            value={password}
            onChangeValue={onChangeValue}
            handleIconClick={handlePasswordDisplay}
            leadingIcon="passcode"
            trailingIcon={`eye${displayPassword ? '-off' : ''}`}
          />
        </div>
      </Tooltip>
      <div className="max-w-2xl mt-6 md:mt-10">
        <Button
          variant="primary"
          type="submit"
          onClick={onSubmit}
          disabled={!isValid}
        >
          Finish
        </Button>
      </div>
    </div>
  );
};

PasswordInput.propTypes = {
  handleUpdateForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isSingleStepForm: PropTypes.bool,
  passwordError: PropTypes.string,
};

PasswordInput.defaultProps = {
  isSingleStepForm: false,
  passwordError: null,
  onSubmit: () => { },
};

export default PasswordInput;
